<div class="flex items-center justify-between rounded px-2 py-0.5 bg-light-bg-darker" [ngStyle]="{ backgroundColor: bgColor() }">
            
                
            <span class="text-xs font-light text-black">
                {{ name() }}
            </span>
        

        @if(canDelete()) {
            <div class="cursor-pointer p-1 flex hover:bg-light-bg-darker transition-all rounded-sm ml-1" (click)="onDelete.emit(value())">
                <hlm-icon
                    class="h-3 w-3"
                    [size]="'sm'"
                    name="lucideX"
                    >
                </hlm-icon>
            </div>
            }
    </div>