import { Routes } from '@angular/router';
import { INBOX_ROUTES } from '@web/app/modules/inbox/views/inbox.routing';

export const LOGGED_IN_ROUTES: Routes = [
            {
                path: '',
                loadChildren: () => INBOX_ROUTES,
            },
            {
                path: '**',
                redirectTo: 'inbox',
            },
      
];
