/**
 * @packageDocumentation
 * @module api.functional.api.views.threads
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { NestiaSimulator } from '@nestia/fetcher/lib/NestiaSimulator';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';
import {
    GetInboxPaginatedQueryParams,
    ViewDTO,
    ViewThreadsQueryResultDTO,
} from '@recapp/dto';
import { ApiResult } from '@recapp/shared-types';
import type { Resolved, Primitive } from 'typia';
import typia from 'typia';

/**
 * @controller ViewsController.getViewThreads
 * @path POST /api/views/threads
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getViewThreads(
    connection: IConnection,
    body: getViewThreads.Input,
): Promise<getViewThreads.Output> {
    return !!connection.simulate
        ? getViewThreads.simulate(connection, body)
        : PlainFetcher.fetch(
              {
                  ...connection,
                  headers: {
                      ...connection.headers,
                      'Content-Type': 'application/json',
                  },
              },
              {
                  ...getViewThreads.METADATA,
                  template: getViewThreads.METADATA.path,
                  path: getViewThreads.path(),
              },
              body,
          );
}
export namespace getViewThreads {
    export type Input = Resolved<
        { view: ViewDTO } & GetInboxPaginatedQueryParams
    >;
    export type Output = Primitive<ApiResult<ViewThreadsQueryResultDTO, never>>;

    export const METADATA = {
        method: 'POST',
        path: '/api/views/threads',
        request: {
            type: 'application/json',
            encrypted: false,
        },
        response: {
            type: 'application/json',
            encrypted: false,
        },
        status: 201,
    } as const;

    export const path = () => '/api/views/threads';
    export const random = (
        g?: Partial<typia.IRandomGenerator>,
    ): Resolved<Primitive<ApiResult<ViewThreadsQueryResultDTO, never>>> =>
        typia.random<Primitive<ApiResult<ViewThreadsQueryResultDTO, never>>>(g);
    export const simulate = (
        connection: IConnection,
        body: getViewThreads.Input,
    ): Output => {
        const assert = NestiaSimulator.assert({
            method: METADATA.method,
            host: connection.host,
            path: path(),
            contentType: 'application/json',
        });
        assert.body(() => typia.assert(body));
        return random(
            'object' === typeof connection.simulate &&
                null !== connection.simulate
                ? connection.simulate
                : undefined,
        );
    };
}
