import { Component, inject, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { lucideMail, lucideMessageSquarePlus, lucidePencil, lucideSearch } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { CommandComponent, InputDirective, ItemDirective, ListComponent, EmptyDirective } from '@ngxpert/cmdk';
import { HlmIconComponent, HlmInputModule, HlmSkeletonComponent, HlmSpinnerComponent } from '@recapp/ui';
import { InboxQueries } from '@web/app/modules/inbox/contexts/inbox.queries';
import { Email } from '@web/app/modules/inbox/models/email.model';
import { NewEmailContext } from '@web/app/modules/inbox/views/new-email/context/new-email.context';
import { ThreadsService } from '@web/app/shared/components/thread-view-modal/services/threads.service';
import { ApplyPurePipe } from '@web/app/shared/pipes/apply-fn.pipe';
import { DateTime } from 'luxon';
import { BehaviorSubject, debounceTime, skip, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-global-search',
    standalone: true,
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    imports: [
        CommandComponent,
        InputDirective,
        ListComponent,
        ItemDirective,
        EmptyDirective,
        TranslateModule,
        HlmInputModule,
        HlmIconComponent,
        ApplyPurePipe,
        HlmSpinnerComponent,
        HlmSkeletonComponent,
    ],
    providers: [
        provideIcons({
            lucideSearch,
            lucideMail,
            lucidePencil,
        }),
    ],
})
export class GlobalSearchComponent implements OnInit {
    private readonly _router = inject(Router);
    private readonly _route = inject(ActivatedRoute);
    private readonly _dialog = inject(MatDialog);
    private readonly _threadService = inject(ThreadsService);

    readonly newEmailContext = inject(NewEmailContext);
    readonly inboxQueries = inject(InboxQueries);

    readonly placeholderArray = new Array(4).fill(0);
    readonly filteredThreads = signal<Email[]>([]);
    readonly isLoading = signal(false);
    private readonly _searchText = new BehaviorSubject('');

    ngOnInit(): void {
        this.filteredThreads.set(this.inboxQueries.inboxThreads().flatMap((t) => t.emails));
        this._searchText
            .pipe(
                skip(1),
                tap(() => this.isLoading.set(true)),
                debounceTime(300),
                switchMap((value) => this._threadService.search(value))
            )
            .subscribe((res) => {
                if (!this._searchText.value.length) {
                    this.filteredThreads.set(this.inboxQueries.inboxThreads().flatMap((t) => t.emails));
                    this.isLoading.set(false);
                    this.highlight(this._searchText.value);
                    return;
                }
                this.filteredThreads.set(res.data);
                this.isLoading.set(false);
                this.highlight(this._searchText.value);
            });
    }

    onSelected(id: string) {
        this._dialog.closeAll();
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { threadId: id },
            queryParamsHandling: 'merge',
        });
    }

    close() {
        this._dialog.closeAll();
    }

    onSearchChange(value: Event) {
        const target = value.target as HTMLInputElement;
        this._searchText.next(target.value);
    }

    isPreviousEmailSameWeek = (index: number): boolean => {
        if (index === 0) return false;
        const currentEmail = this.filteredThreads()[index];
        const previousEmail = this.filteredThreads()[index - 1];
        return DateTime.fromJSDate(currentEmail.socialCreatedAt ?? currentEmail.createdAt).hasSame(
            DateTime.fromJSDate(previousEmail.socialCreatedAt ?? currentEmail.createdAt),
            'day'
        );
    };

    highlight(searchText: string) {
        setTimeout(() => {
            document.querySelectorAll('.item-content').forEach((el) => {
                if (!el.textContent) return;
                const text = el.textContent;
                const innerHTML = text.replace(
                    new RegExp(searchText, 'gi'),
                    (match) => `<span class="highlight bg-light-bg-darker">${match}</span>`
                );
                el.innerHTML = innerHTML;
            });

            document.querySelectorAll('.item-subject').forEach((el) => {
                if (!el.textContent) return;
                const text = el.textContent;
                const innerHTML = text.replace(
                    new RegExp(searchText, 'gi'),
                    (match) => `<span class="highlight bg-light-bg-darker">${match}</span>`
                );
                el.innerHTML = innerHTML;
            });
        }, 100);
    }
}
