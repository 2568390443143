import { ThreadDTO } from '../mails/thread.dto';

export interface ViewDTO {
    id: string;
    name: string;
    description?: string;
    color: string;
    icon: string;
    groupBy: {
        key: ViewGroupByKey;
        order: ViewGroupByOrder;
    }[];
    filters: {
        operator: ViewFilterOperator;
        fields: {
            key: ViewFilters;
            value: {
                comparator: ViewFilterComparator;
                value: string | number | boolean | Date | string[];
            };
        }[];
    };
    userId: string;
    isSystem: boolean;
    readonly createdAt: string;
    readonly updatedAt: string;
}

export enum ViewGroupByOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export enum ViewGroupByKey {
    Priority = 'priority',
    Sender = 'emails.author.emailAdress',
    Date = 'lastEmailDate',
    Label = 'labelIds',
}
export type ViewFilterKeys =
    | 'Destination'
    | 'Sender'
    | 'Subject'
    | 'Content'
    | 'Label'
    | 'Date'
    | 'Priority';

export enum ViewFilters {
    Destination = 'emails.destination.emailAdress',
    Sender = 'emails.author.emailAdress',
    Subject = 'emails.subject',
    Content = 'emails.content',
    Label = 'labelIds',
    Date = 'createdAt',
    Priority = 'priority',
}

export type ViewFilterComparatorKeys =
    | 'Equal'
    | 'NotEqual'
    | 'GreaterThan'
    | 'GreaterThanOrEqual'
    | 'LessThan'
    | 'LessThanOrEqual'
    | 'Contains'
    | 'NotContains'
    | 'In'
    | 'NotIn';
export enum ViewFilterComparator {
    Equal = 'eq',
    NotEqual = 'ne',
    GreaterThan = 'gt',
    GreaterThanOrEqual = 'gte',
    LessThan = 'lt',
    LessThanOrEqual = 'lte',
    Contains = 'contains',
    NotContains = 'notContains',
    In = 'in',
    NotIn = 'nin',
}

export type ViewFilterOperatorKeys = 'And' | 'Or';
export enum ViewFilterOperator {
    And = 'and',
    Or = 'or',
}

export interface ViewThreadsQueryResultDTO {
    groups: { [key: string]: any; threads: ThreadDTO[] }[];
    hasMore: boolean;
}
