import { Route } from '@angular/router';

export const INBOX_ROUTES: Route[] = [
    {
        path: '',
        loadComponent() {
            return import('./inbox.component').then((m) => m.InboxComponent);
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'inbox',
            },
            {
                path: 'inbox',
                pathMatch: 'full',
                loadComponent() {
                    return import('./inbox-list-view/inbox-list-view.component').then((m) => m.InboxListViewComponent);
                },
            },
            {
                path: 'board',
                pathMatch: 'full',
                loadComponent() {
                    return import('./inbox-card-view/inbox-card-view.component').then((m) => m.InboxCardViewComponent);
                },
            },
            {
                path: 'views/:viewId',
                pathMatch: 'full',
                loadComponent() {
                    return import('./custom-view/custom-view.component').then((m) => m.CustomViewComponent);
                },
            },
            {
                path: '**',
                redirectTo: 'inbox',
            },
        ],
    },
];
