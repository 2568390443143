/**
 * @packageDocumentation
 * @module api.functional.api.views
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from '@nestia/fetcher';
import { NestiaSimulator } from '@nestia/fetcher/lib/NestiaSimulator';
import { PlainFetcher } from '@nestia/fetcher/lib/PlainFetcher';
import { ViewDTO } from '@recapp/dto';
import { ApiResult, SuccessResult } from '@recapp/shared-types';
import type { Primitive, Resolved } from 'typia';
import typia from 'typia';

export * as threads from './threads';

/**
 * @controller ViewsController.getAllByUser
 * @path GET /api/views
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getAllByUser(
    connection: IConnection,
): Promise<getAllByUser.Output> {
    return !!connection.simulate
        ? getAllByUser.simulate(connection)
        : PlainFetcher.fetch(connection, {
              ...getAllByUser.METADATA,
              template: getAllByUser.METADATA.path,
              path: getAllByUser.path(),
          });
}
export namespace getAllByUser {
    export type Output = Primitive<ApiResult<Array<ViewDTO>, never>>;

    export const METADATA = {
        method: 'GET',
        path: '/api/views',
        request: null,
        response: {
            type: 'application/json',
            encrypted: false,
        },
        status: 200,
    } as const;

    export const path = () => '/api/views';
    export const random = (
        g?: Partial<typia.IRandomGenerator>,
    ): Resolved<Primitive<ApiResult<Array<ViewDTO>, never>>> =>
        typia.random<Primitive<ApiResult<Array<ViewDTO>, never>>>(g);
    export const simulate = (connection: IConnection): Output => {
        return random(
            'object' === typeof connection.simulate &&
                null !== connection.simulate
                ? connection.simulate
                : undefined,
        );
    };
}

/**
 * @controller ViewsController.createView
 * @path POST /api/views
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createView(
    connection: IConnection,
    view: createView.Input,
): Promise<createView.Output> {
    return !!connection.simulate
        ? createView.simulate(connection, view)
        : PlainFetcher.fetch(
              {
                  ...connection,
                  headers: {
                      ...connection.headers,
                      'Content-Type': 'application/json',
                  },
              },
              {
                  ...createView.METADATA,
                  template: createView.METADATA.path,
                  path: createView.path(),
              },
              view,
          );
}
export namespace createView {
    export type Input = Resolved<ViewDTO>;
    export type Output = Primitive<ApiResult<SuccessResult, never>>;

    export const METADATA = {
        method: 'POST',
        path: '/api/views',
        request: {
            type: 'application/json',
            encrypted: false,
        },
        response: {
            type: 'application/json',
            encrypted: false,
        },
        status: 201,
    } as const;

    export const path = () => '/api/views';
    export const random = (
        g?: Partial<typia.IRandomGenerator>,
    ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
        typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
    export const simulate = (
        connection: IConnection,
        view: createView.Input,
    ): Output => {
        const assert = NestiaSimulator.assert({
            method: METADATA.method,
            host: connection.host,
            path: path(),
            contentType: 'application/json',
        });
        assert.body(() => typia.assert(view));
        return random(
            'object' === typeof connection.simulate &&
                null !== connection.simulate
                ? connection.simulate
                : undefined,
        );
    };
}

/**
 * @controller ViewsController.updateView
 * @path PUT /api/views
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateView(
    connection: IConnection,
    view: updateView.Input,
): Promise<updateView.Output> {
    return !!connection.simulate
        ? updateView.simulate(connection, view)
        : PlainFetcher.fetch(
              {
                  ...connection,
                  headers: {
                      ...connection.headers,
                      'Content-Type': 'application/json',
                  },
              },
              {
                  ...updateView.METADATA,
                  template: updateView.METADATA.path,
                  path: updateView.path(),
              },
              view,
          );
}
export namespace updateView {
    export type Input = Resolved<ViewDTO>;
    export type Output = Primitive<ApiResult<SuccessResult, never>>;

    export const METADATA = {
        method: 'PUT',
        path: '/api/views',
        request: {
            type: 'application/json',
            encrypted: false,
        },
        response: {
            type: 'application/json',
            encrypted: false,
        },
        status: 200,
    } as const;

    export const path = () => '/api/views';
    export const random = (
        g?: Partial<typia.IRandomGenerator>,
    ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
        typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
    export const simulate = (
        connection: IConnection,
        view: updateView.Input,
    ): Output => {
        const assert = NestiaSimulator.assert({
            method: METADATA.method,
            host: connection.host,
            path: path(),
            contentType: 'application/json',
        });
        assert.body(() => typia.assert(view));
        return random(
            'object' === typeof connection.simulate &&
                null !== connection.simulate
                ? connection.simulate
                : undefined,
        );
    };
}

/**
 * @controller ViewsController.deleteView
 * @path DELETE /api/views/:viewId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteView(
    connection: IConnection,
    viewId: string,
): Promise<deleteView.Output> {
    return !!connection.simulate
        ? deleteView.simulate(connection, viewId)
        : PlainFetcher.fetch(connection, {
              ...deleteView.METADATA,
              template: deleteView.METADATA.path,
              path: deleteView.path(viewId),
          });
}
export namespace deleteView {
    export type Output = Primitive<ApiResult<SuccessResult, never>>;

    export const METADATA = {
        method: 'DELETE',
        path: '/api/views/:viewId',
        request: null,
        response: {
            type: 'application/json',
            encrypted: false,
        },
        status: 200,
    } as const;

    export const path = (viewId: string) =>
        `/api/views/${encodeURIComponent(viewId?.toString() ?? 'null')}`;
    export const random = (
        g?: Partial<typia.IRandomGenerator>,
    ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
        typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
    export const simulate = (
        connection: IConnection,
        viewId: string,
    ): Output => {
        const assert = NestiaSimulator.assert({
            method: METADATA.method,
            host: connection.host,
            path: path(viewId),
            contentType: 'application/json',
        });
        assert.param('viewId')(() => typia.assert(viewId));
        return random(
            'object' === typeof connection.simulate &&
                null !== connection.simulate
                ? connection.simulate
                : undefined,
        );
    };
}
