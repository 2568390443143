<div class="flex h-full flex-col w-full">
    <div class="flex items-center justify-between p-4">
        <div class="text-md font-medium text-black">{{ 'new_message.title' | translate }}</div>

        <div class="flex items-center gap-x-2">
            @if(!isInModal()) {
                <button
                    class="!bg-white px-[0.5rem] text-light hover:!bg-light-bg-darker"
                    hlmBtn
                    variant="outline"
                    size="sm"
                    [matTooltip]="'common.reduce' | translate"
                    (click)="toggleExpanded()">
                    <hlm-icon name="lucideMinus" size="sm"></hlm-icon>
                </button>
                <button
                    class="!bg-white px-[0.5rem] text-light hover:!bg-light-bg-darker"
                    hlmBtn
                    variant="outline"
                    size="sm"
                    [matTooltip]="'common.maximize' | translate"
                    (click)="newEmailContext.openFullThread(thread())">
                    <hlm-icon name="lucideMaximize2" size="sm"></hlm-icon>
                </button>
            }
            <button
                class="!bg-white px-[0.5rem] text-light hover:!bg-light-bg-darker"
                hlmBtn
                variant="outline"
                size="sm"
                [matTooltip]="'common.close' | translate"
                (click)="close()">
                <hlm-icon name="lucideX" size="sm"></hlm-icon>
            </button>
        </div>
    </div>
    <div class="flex flex-col gap-y-4 p-4 pt-3">
        <div class="flex w-full justify-between">
            <div class="flex w-full gap-2 border-b border-light-bg-darker pb-2" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                <span class="text-xs text-light">{{ 'common.to' | translate }}:</span>
                
                <div class="flex items-center gap-1 flex-wrap flex-grow">
                    @for (email of thread().getDestinationEmails(newEmailContext.currentUser()!.email); track $index) {
                        <app-chip
                            [name]="email"
                            [value]="email"
                            [canDelete]="true"
                            (onDelete)="removeDestination(null, $event)"></app-chip>
                    }
                    <input
                        class="unstyled-input flex-grow text-sm text-light"
                            type="text"
                            autofocus="true"
                            matInput
                            [formControl]="emailFormControl"
                            [matAutocomplete]="auto"
                            [matAutocompleteConnectedTo]="origin"
                            (keydown.backspace)="removeDestination($event)"
                            />
                </div>
            </div>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addDestination($event)" class="!shadow-none border border-light-bg-darker !rounded-[5px] mt-2 !bg-light-bg">
            @for(contact of filteredContacts(); track $index) {
                <mat-option [value]="contact.email" class="focus:!bg-light-bg-darker">
                    <div class="flex items-center gap-x-2">
                        <app-avatar [avatars]="[{
                            fallback: contact.name ?? contact.email
                        }]"
                        [size]="'sm'"
                        ></app-avatar>
                        @if(!contact.name) {
                            <span class="text-sm text-black">{{ contact.email }}</span>
                        } @else {
                            <div class="flex gap-x-2 items-center">

                                <span class="text-sm text-black">{{ contact.name }}</span>
                                <span class="text-xs text-light">{{ contact.email }}</span>
                            </div>
                            }
                    </div>
                </mat-option>
            }
            @if(isNewOption()) {
                <mat-option [value]="emailFormControl.value" class="focus:!bg-light-bg-darker">    
                    <span class="text-sm text-black">{{ emailFormControl.value }}</span>
                </mat-option>
            }
        </mat-autocomplete>

        <div class="flex w-full items-center gap-x-2 border-b border-light-bg-darker pb-2">
            <span class="text-xs text-light">{{ 'common.subject' | translate }}:</span>
            <input [formControl]="subjectFormControl" class="unstyled-input w-full text-sm text-light" type="email" autofocus="true" />
        </div>
    </div>
    <div class="flex grow flex-col justify-between">
        <div class="relative flex flex-col h-[43vh]">
            <!-- <button class="absolute right-0 top-0 gap-x-2 text-xs text-secondary-accent" hlmBtn variant="link" size="sm">
                <hlm-icon name="lucideWand2" size="xs"></hlm-icon>
                <span>
                    {{ 'thread_view_modal.ask_recapp' | translate }}
                </span>
            </button> -->

            <app-text-editor
                class="h-full w-full resize-none overflow-y-auto border-none text-xs text-light placeholder-light outline-none transition-all duration-300 p-4 pt-0"
                [autofocus]="true"
                [editorId]="editorId"
                [enableAi]="false"
                [aiAutoCompleteCallback]="aiAutoCompleteCallback"
                [initialData]="thread().getLastEmail().content"
                (onChange)="onEditorChange($event)"
            ></app-text-editor>
            
            <div
                class="px-4 mt-4 text-black"
                [innerHTML]="user().getDefaultSignature()"
            ></div>

            <app-attachments-preview
                class="p-4"
                [wrap]="true"
                [canRemoveAttachments]="true"
                [attachments]="thread().getLastEmail().attachments"
                (onRemoveAttachment)="removeAttachment($event)"
                ></app-attachments-preview>
        </div>
        <div class="flex items-center gap-x-2 border-t border-light-bg-darker p-4">
            <button size="sm" variant="default" hlmBtn (click)="send()">{{ 'common.send' | translate }}</button>
            <!-- <button class="text-light" size="sm" variant="ghost" hlmBtn>{{ 'common.schedule' | translate }}</button> -->
            <div class="flex items-center gap-x-1">
                <button class="text-light" id="attachment-input-trigger" hlmBtn size="sm" variant="ghost">
                    <hlm-icon name="lucidePaperclip" size="sm"></hlm-icon>
                </button>
                <!-- <button class="text-light" hlmBtn size="sm" variant="ghost"><hlm-icon name="lucidePencil" size="sm"></hlm-icon></button>
                <button class="text-light" hlmBtn size="sm" variant="ghost">
                    <hlm-icon name="lucideMoreHorizontal" size="sm"></hlm-icon>
                </button> -->
            </div>
            <div class="flex grow justify-end">
                <button (click)="removeDraft()" class="text-light" hlmBtn size="sm" variant="ghost">
                    <hlm-icon name="lucideTrash" size="sm"></hlm-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Hidden file input -->
<app-file-uploader
    targetContainerId="attachment-input-trigger"
    (finishedUpload)="onFilesSelected($event)"
    (filesSelected)="onFilesSelected($event)"></app-file-uploader>
