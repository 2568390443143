/**
 * @packageDocumentation
 * @module api.functional.api.emails.drafts
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type { ThreadDTO } from "@recapp/dto";
import type { ApiResult, SuccessResult } from '@recapp/shared-types';

export * as answer from "./answer";

/**
 * @controller EmailsController.upsert
 * @path POST /api/emails/drafts
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function upsert(
  connection: IConnection,
  threadDto: upsert.Input,
): Promise<upsert.Output> {
  return !!connection.simulate
    ? upsert.simulate(connection, threadDto)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...upsert.METADATA,
          template: upsert.METADATA.path,
          path: upsert.path(),
        },
        threadDto,
      );
}
export namespace upsert {
  export type Input = Resolved<ThreadDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/emails/drafts",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/emails/drafts";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    threadDto: upsert.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(threadDto));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
