import { ChangeDetectionStrategy, input, OnInit, output, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import EditorJS, { OutputData, ToolConstructable } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Underline from '@editorjs/underline';
import InlineCode from '@editorjs/inline-code';
import HtmlParser from './html-parser';
// @ts-ignore
import DragDrop from 'editorjs-drag-drop';
// @ts-ignore
import Undo from 'editorjs-undo';
import Paragraph from '@editorjs/paragraph';
// @ts-ignore
import Checklist from '@editorjs/checklist';
// @ts-ignore
import SimpleImage from '@editorjs/simple-image';
import { NgClass } from '@angular/common';
import { debounceTime, Subject } from 'rxjs';
import AIText from './plugins/ai-text/ai-text.plugin'


@Component({
    selector: 'app-text-editor',
    standalone: true,
    imports: [FormsModule, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent implements OnInit {
    readonly editorId = input<string>('editorjs');
    readonly hideToolbar = input<boolean>(false);
    readonly initialData = input<string | OutputData>();
    readonly autofocus = input<boolean>(false);
    readonly readonly = input<boolean>();
    readonly onChange = output<string>();
    readonly onChangeOutputData = output<OutputData>();
    readonly onFocus = output<void>();
    readonly checkboxOnly = input<boolean>(false);
    readonly enableAi = input<boolean>(false);
    readonly onReplaceText = input<Subject<string>>();
    readonly aiAutoCompleteCallback = input<(text: string) => Promise<string>>();

    
    readonly parser = new HtmlParser();
    readonly textChangeSubject$ = new Subject<string>();
    readonly dataChangeSubject$ = new Subject<OutputData>();

    editor: EditorJS | undefined;
    

    ngOnInit(): void {

        this.onReplaceText()?.asObservable().subscribe(async (value) => {
            await this.editor?.clear();
            await this.editor?.blocks.renderFromHTML(value);
        });
        
        this.textChangeSubject$.pipe(
            debounceTime(300),
        ).subscribe((value) => {
            this.onChange.emit(value);
        })

        this.dataChangeSubject$.pipe(
            debounceTime(300),
        ).subscribe((value) => {
            this.onChangeOutputData.emit(value);
        })

        this.editor = new EditorJS({
            holder: this.editorId(),
            placeholder: this.checkboxOnly() ? 'Add a task' : 'Type something...',
            defaultBlock: this.checkboxOnly() ? 'checklist' : this.enableAi() ? 'aiText' : 'paragraph',
            minHeight: 100,
            i18n: {
                messages: {
                    ui: {
                        toolbox: {
                            toolbox: 'Tools',
                        },
                    },
                },
            },
            // Define the tools here
            tools: this.checkboxOnly()
                ? {
                      checklist: {
                          class: Checklist as any,
                          inlineToolbar: true,
                          shortcut: 'CMD+SHIFT+X',
                      },
                  }
                : {
                      checklist: {
                          class: Checklist as any,
                          inlineToolbar: true,
                          shortcut: 'CMD+SHIFT+X',
                      },
                      header: {
                          class: Header as any,
                          inlineToolbar: true,
                          config: {
                              placeholder: 'Header',
                          },
                          shortcut: 'CMD+SHIFT+H',
                      },
                      list: {
                          class: List as any,
                          inlineToolbar: true,
                          shortcut: 'CMD+SHIFT+L',
                      },
                      underline: Underline,

                      inlineCode: {
                          class: InlineCode,
                          shortcut: 'CMD+SHIFT+C',
                      },
                      paragraph: {
                          class: Paragraph as any,
                          shortcut: 'CMD+SHIFT+P',
                          config: {
                              preserveBlank: true,
                          },
                      },
                      image: {
                          class: SimpleImage as any,
                      },
                      aiText: {
                            class: AIText as unknown as ToolConstructable,
                            config: {
                                callback: this.aiAutoCompleteCallback()
                            }
                        },
                  },
            // Save content changes
            onChange: (api, event) => {
                api.saver.save().then((outputData) => {
                    this.textChangeSubject$.next(
                        outputData.blocks
                            .map((block) => {
                                return this.parser.parseBlock(block);
                            })
                            .join('')
                    );

                    this.dataChangeSubject$.next(outputData);
                });
            },
            onReady: async () => {
                
                if (this.initialData()) {
                    if (typeof this.initialData() === 'string') {
                        await this.editor?.blocks.renderFromHTML(this.initialData()! as string);
                        
                    } else {
                        await this.editor?.render(this.initialData()! as OutputData);
                    }
                }
                new Undo({ editor: this.editor });
                new DragDrop(this.editor);

                // Select the last content-editable element
                if(this.autofocus()) {
                    const elements = document.getElementById(this.editorId())?.querySelectorAll('[contenteditable="true"]');
                    const lastElement = elements?.[elements.length - 1] as HTMLElement;
                    
                    setTimeout(() => {
                        lastElement.focus();
                        // select all the content in the element            
                        document.execCommand('selectAll', false, undefined);
                        // collapse selection to the end
                        document.getSelection()?.collapseToEnd();
                    }, 200);
                }
            },
            // Initial data
        });
    }
}
