/**
 * @packageDocumentation
 * @module api.functional.api.threads.asnwer.generate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import { ThreadAutoCompleteSentenceBodyDTO, ThreadAutoCompleteSentenceDTO } from "@recapp/dto";
import { ApiResult } from "@recapp/shared-types";
import type { Resolved, Primitive } from "typia";
import typia from "typia";


/**
 * @controller ThreadsController.completeSentence
 * @path POST /api/threads/asnwer/generate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function completeSentence(
  connection: IConnection,
  body: completeSentence.Input,
): Promise<completeSentence.Output> {
  return !!connection.simulate
    ? completeSentence.simulate(connection, body)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...completeSentence.METADATA,
          template: completeSentence.METADATA.path,
          path: completeSentence.path(),
        },
        body,
      );
}
export namespace completeSentence {
  export type Input = Resolved<ThreadAutoCompleteSentenceBodyDTO>;
  export type Output = Primitive<
    ApiResult<ThreadAutoCompleteSentenceDTO, never>
  >;

  export const METADATA = {
    method: "POST",
    path: "/api/threads/asnwer/generate",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/threads/asnwer/generate";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<ThreadAutoCompleteSentenceDTO, never>>> =>
    typia.random<Primitive<ApiResult<ThreadAutoCompleteSentenceDTO, never>>>(g);
  export const simulate = (
    connection: IConnection,
    body: completeSentence.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(body));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
