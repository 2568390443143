import { computed, inject, Injectable, signal } from '@angular/core';
import { View } from '@web/app/modules/inbox/models/view.model';
import { ViewService } from '@web/app/modules/inbox/services/views.service';

@Injectable({ providedIn: 'root' })
export class ViewsContext {
    private readonly _viewService = inject(ViewService);
    readonly views = signal<View[]>([]);
    readonly currentViewId = signal<string | null>(null);

    readonly currentView = computed(() => this.views().find((view) => view.id === this.currentViewId()) || null);

    init() {
        this._viewService.getViews().subscribe((res) => {
            this.views.set(res.data);
        });
    }

    updateView(newView: View) {
        this.views.update((views) => {
            const currentView = this.currentView();
            if (!currentView) {
                return views;
            }

            return views.map((view) => (view.id === newView.id ? newView : view));
        });
    }
}
