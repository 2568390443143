import { LabelDTO } from '@recapp/dto';
import { BaseEntity, EntityConstructor } from '@recapp/shared-types';

type LabelProps = EntityConstructor<Label>;

export class Label extends BaseEntity {
    name: string;
    color: LabelColor;
    userId: string;
    type: LabelType;
    socialId?: string;

    constructor(props: LabelProps) {
        super(props);
        this.name = props.name;
        this.color = props.color;
        this.userId = props.userId;
        this.type = props.type;
        this.socialId = props.socialId;
    }

    static fromDto(dto: LabelDTO): Label {
        return new Label({
            id: dto.id,
            name: dto.name,
            color: dto.color,
            userId: dto.userId,
            type: dto.type as LabelType,
            socialId: dto.socialId,
        });
    }

    toDto(): LabelDTO {
        return {
            id: this.id,
            name: this.name,
            color: this.color,
            userId: this.userId,
            type: this.type,
            socialId: this.socialId,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    getRGBABgColor(): string {
        return this.color.backgroundColor + 50;
    }

    isSubLabel(): boolean {
        return this.name.includes('/');
    }

    getNameWithoutParent(): string {
        return this.name.split('/')[1];
    }

    getParentName(): string {
        return this.name.split('/')[0];
    }

    isSystemLabel(): boolean {
        return this.type === LabelType.SYSTEM;
    }
}

export enum LabelType {
    SYSTEM = 'system',
    USER = 'user',
}

export type LabelColor = {
    textColor: string;
    backgroundColor: string;
};
