import { idSchema } from '../common';
import { UserDTO } from '../users';
import { z } from 'zod';

export type AuthSuccessDTO = {
    token: string;
    user: UserDTO;
    isNewUser: boolean;
};

export const updatePasswordSchema = z.object({
    password: z.string().min(8),
    userId: idSchema,
});

export interface UpdatePasswordDTO
    extends z.infer<typeof updatePasswordSchema> {}

export const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8),
});

export interface LoginDTO extends z.infer<typeof loginSchema> {}
