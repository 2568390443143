import { PlatformKey } from '@recapp/shared-types';
import { z } from 'zod';
import { idSchema } from '../common';

export const createPlatformSchema = z.object({
    key: z.nativeEnum(PlatformKey),
    userId: idSchema,
    credential: z.object({
        key: z.string(),
        authId: z.string(),
        refreshToken: z.string(),
        accessToken: z.string(),
        accessTokenExpiresAt: z.date(),
        scope: z.string(),
        tokenType: z.string(),
        createdAt: z.date(),
        updatedAt: z.date(),
    }),
});

export interface CreatePlatformDTO
    extends z.infer<typeof createPlatformSchema> {}
