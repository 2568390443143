import { createAction, props } from '@ngrx/store';
import { User } from '../models/user.model';
import { Update } from '@ngrx/entity';

export const addUser = createAction('[User] Add User', props<{ user: User }>());

export const upsertUser = createAction('[User] Upsert User', props<{ user: User }>());

export const addUsers = createAction('[User] Add Users', props<{ users: User[] }>());

export const upsertUsers = createAction('[User] Upsert Users', props<{ users: User[] }>());

export const updateUser = createAction('[User] Update User', props<{ user: Update<User> }>());

export const updateUsers = createAction('[User] Update Users', props<{ users: Update<User>[] }>());

export const deleteUser = createAction('[User] Delete User', props<{ id: string }>());

export const deleteUsers = createAction('[User] Delete Users', props<{ ids: string[] }>());

export const loadUsers = createAction('[User] Load Users', props<{ users: User[] }>());

export const clearUsers = createAction('[User] Clear Users');

export const selectUser = createAction('[User] Select User', props<{ id: string }>());

export const logoutUser = createAction('[User] Logout User');

export const logoutAllUsers = createAction('[User] Logout All Users');
