import { inject, Injectable } from '@angular/core';
import { ViewDTO } from '@recapp/dto';
import { ApiResult, PaginationResult, SuccessResult } from '@recapp/shared-types';
import { TrpcService } from '@web/app/core/services/trpc.service';
import { Thread } from '@web/app/modules/inbox/models/thread.model';
import { View } from '@web/app/modules/inbox/models/view.model';
import { from, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ViewService {
    private readonly _trpcService = inject(TrpcService);

    getViews(): Observable<ApiResult<View[]>> {
        return from(this._trpcService.api.views.getAllByUser(this._trpcService.connection)).pipe(
            map((res) => ({
                ...res,
                data: res.data.map((view) => View.fromDto(view)),
            }))
        );
    }

    getViewThreads({
        view,
        labelsNames,
        pageNumber,
        pageSize,
    }: {
        view: ViewDTO;
        labelsNames: string[];
        pageNumber: number;
        pageSize: number;
    }): Promise<ApiResult<{ [key: string]: any; threads: Thread[] }[], PaginationResult>> {
        return this._trpcService.api.views.threads
            .getViewThreads(this._trpcService.connection, {
                view,
                labelsNames,
                pageNumber,
                pageSize,
            })
            .then(
                (res) =>
                    new ApiResult(
                        res.data.groups.map((g) => ({
                            ...g,
                            threads: g.threads.map((t) => Thread.fromDto(t)),
                        })),
                        { hasMore: res.data.hasMore, nextPageNumber: pageNumber + 1 }
                    )
            );
    }

    createView(view: View): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.views.createView(this._trpcService.connection, view.toDto()).then((res) => new ApiResult(res.data));
    }

    updateView(view: View): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.views.updateView(this._trpcService.connection, view.toDto()).then((res) => new ApiResult(res.data));
    }

    deleteView(viewId: string): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.views.deleteView(this._trpcService.connection, viewId).then((res) => new ApiResult(res.data));
    }
}
