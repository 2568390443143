import { ErrorDefinition, ModuleName } from '../types';

const errorFromModule = ModuleName.EMAILS;

export enum EmailsError {
    EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
    SEND_FAILED = 'SEND_FAILED',
    UPDATE_READ_STATE_FAILED = 'UPDATE_READ_STATE_FAILED',
    TRASH_FAILED = 'TRASH_FAILED',
    UNTRASH_FAILED = 'UNTRASH_FAILED',
    ARCHIVE_FAILED = 'ARCHIVE_FAILED',
    UNARCHIVE_FAILED = 'UNARCHIVE_FAILED',
    DELETE_FAILED = 'DELETE_FAILED',
}

export const EMAILS_ERRORS: ErrorDefinition<EmailsError> = {
    [EmailsError.EMAIL_NOT_FOUND]: {
        errorFromModule,
        message: 'EMAIL not found',
        statusCode: 404,
    },

    [EmailsError.SEND_FAILED]: {
        errorFromModule,
        message: 'Failed to send email',
        statusCode: 504,
    },

    [EmailsError.UPDATE_READ_STATE_FAILED] : {
        errorFromModule,
        message: 'Failed to update read state',
        statusCode: 504,
    },

    [EmailsError.TRASH_FAILED]: {
        errorFromModule,
        message: 'Failed to trash email',
        statusCode: 504,
    },

    [EmailsError.UNTRASH_FAILED]: {
        errorFromModule,
        message: 'Failed to untrash email',
        statusCode: 504,
    },

    [EmailsError.ARCHIVE_FAILED]: {
        errorFromModule,
        message: 'Failed to archive email',
        statusCode: 504,
    },

    [EmailsError.UNARCHIVE_FAILED]: {
        errorFromModule,
        message: 'Failed to unarchive email',
        statusCode: 504,
    },

    [EmailsError.DELETE_FAILED]: {
        errorFromModule,
        message: 'Failed to delete email',
        statusCode: 504,
    },
};
