import { AttachmentDTO, CalendarEvent } from '@recapp/dto';
import { AttachmentType, BaseEntity, EntityConstructor } from '@recapp/shared-types';
import { DateTime } from 'luxon';

type AttachmentProps = EntityConstructor<Attachment>;

export class Attachment<Metadata = any> extends BaseEntity {
    name: string;
    type: AttachmentType;
    url: string;
    metadata?: Metadata;
    size?: number;
    constructor(props: AttachmentProps) {
        super(props);
        this.name = props.name;
        this.type = props.type;
        this.url = props.url;
        this.size = props.size;
        this.metadata = props.metadata;
    }

    static fromDto(dto: AttachmentDTO): Attachment {
        return new Attachment({
            id: dto.id,
            name: dto.name,
            type: dto.type,
            url: dto.url,
            size: dto.size,
            createdAt: new Date(dto.createdAt),
            metadata: dto.metadata,
        });
    }

    static getAttachmentTypeFromFileType = (fileType: string): AttachmentType => {
        if (fileType.includes('image')) {
            return AttachmentType.IMAGE;
        }
        if (fileType.includes('video')) {
            return AttachmentType.VIDEO;
        }
        if (fileType.includes('audio')) {
            return AttachmentType.AUDIO;
        }
        if (fileType.includes('pdf')) {
            return AttachmentType.PDF;
        }
        if (fileType.includes('calendar')) {
            return AttachmentType.CALENDAR_EVENT;
        }
        return AttachmentType.DOCUMENT;
    };

    toDto(): AttachmentDTO {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            url: this.url,
            size: this.size,
            metadata: this.metadata as any,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    getAttachmentIcon = (): string => {
        switch (this.type) {
            case AttachmentType.IMAGE:
                return 'lucideImage';
            case AttachmentType.VIDEO:
                return 'lucideVideo';
            case AttachmentType.AUDIO:
                return 'lucideMusic';
            case AttachmentType.DOCUMENT:
                return 'lucidePaperclip';
            case AttachmentType.LINK:
                return 'lucideLink';
            default:
                return 'lucidePaperclip';
        }
    };

    isPdf = (): boolean => this.type === AttachmentType.PDF;

    isCalendarEvent = (): boolean => this.type === AttachmentType.CALENDAR_EVENT;

    getEventRemainingTime(): string {
        if (!this.isCalendarEvent()) {
            return '';
        }

        const event = this.metadata as CalendarEvent;
        const now = DateTime.now();
        const end = DateTime.fromISO(event.end?.dateTime);
        const start = DateTime.fromISO(event.start?.dateTime);
        if (event.end?.dateTime && end.diff(now).milliseconds < 0) {
            return 'passed';
        }

        if (start.diff(now).milliseconds < 0) {
            return 'ongoing';
        }

        if (start.diff(now, 'days').days > 7) {
            return start.toLocaleString(DateTime.DATE_MED);
        }

        return start.toRelative({ base: now })!;
    }
}
