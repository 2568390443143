<div class="flex h-full w-full items-center justify-center bg-transparent">
    <div class="mt-6 w-[50vw]">
        @if (shouldDisplayWithDocumentViewer()) {
            <ngx-doc-viewer
                viewer="google"
                style="width: 100%; height: 100vh; background: transparent"
                [url]="currentAttachment().url"
                (loaded)="onViewerLoaded()"></ngx-doc-viewer>
        } @else {
            <img
                class="max-h-full max-w-full object-contain"
                alt="Attachment preview"
                [src]="currentAttachment().url"
                (load)="onViewerLoaded()" />
        }
    </div>
    @if (!isDocumentViewerLoaded()) {
        <div class="fixed top-[50%] z-50 -translate-x-1/2 -translate-y-1/2 transform">
            <hlm-spinner></hlm-spinner>
        </div>
    }
</div>

<div class="fixed top-[50%] flex w-full -translate-y-1/2 transform items-center justify-between px-4">
    @if (hasPreviousAttachment()) {
        <button class="rounded-full p-2" hlmBtn variant="ghost" (click)="onPreviousAttachment()">
            <hlm-icon class="text-white dark:text-black" name="lucideChevronLeft"></hlm-icon>
        </button>
    } @else {
        <div class="w-8"></div>
    }
    @if (hasNextAttachment()) {
        <button class="rounded-full p-2" hlmBtn variant="ghost" (click)="onNextAttachment()">
            <hlm-icon class="rotate-180 transform text-white dark:text-black" name="lucideChevronLeft"></hlm-icon>
        </button>
    }
</div>

<!-- other -->
<div class="fixed left-0 top-0 flex w-full items-center justify-between px-4 py-3" id="attachment-preview-actions-top">
    <div class="flex items-center gap-x-3">
        <button class="rounded-full p-2" hlmBtn variant="ghost" (click)="closeDialog()">
            <hlm-icon class="text-white dark:text-black" name="lucideArrowLeft"></hlm-icon>
        </button>

        <div class="flex items-center gap-x-2">
            @if (currentAttachment().isPdf()) {
                <img src="/assets/icons/pdf.svg" alt="pdf file" width="24" height="24" />
            } @else {
                <hlm-icon class="text-attachment-color" [name]="currentAttachment().getAttachmentIcon()"></hlm-icon>
            }
            <span class="truncate font-medium text-white dark:text-black">{{ currentAttachment().name }}</span>
        </div>
    </div>
    <div class="flex items-center gap-x-2">
        <!-- <button class="rounded-full p-2" hlmBtn variant="ghost" (click)="printAttachment()">
            <hlm-icon class="text-white dark:text-black" name="lucidePrinter"></hlm-icon>
        </button> -->
        <button class="rounded-full p-2" hlmBtn variant="ghost" (click)="downloadAttachment()">
            <hlm-icon class="text-white dark:text-black" name="lucideDownload"></hlm-icon>
        </button>
    </div>
</div>
