import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, inject, input, viewChild, ElementRef, output } from '@angular/core';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { UppyAngularDashboardModule, UppyAngularStatusBarModule, UppyAngularDragDropModule } from '@uppy/angular';
import { EmailsService } from '@web/app/modules/inbox/services/emails.service';
import { Attachment } from '@web/app/modules/inbox/models/attachment.model';
@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UppyAngularDashboardModule, UppyAngularStatusBarModule, UppyAngularDragDropModule],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
    readonly targetContainerId = input.required<string>();
    readonly finishedUpload = output<Attachment[]>();
    readonly filesSelected = output<Attachment[]>();
    readonly fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');

    uppy: Uppy | undefined;
    private readonly _emailService = inject(EmailsService);

    ngOnInit(): void {
        document.getElementById(this.targetContainerId())?.addEventListener('click', () => {
            this.openFilePicker();
        });

        this.uppy = new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/*', 'application/pdf', 'video/*'], // Allowed file types
            },
            autoProceed: true, // Start upload automatically after file selection
        });

        this.uppy.use(AwsS3, {
            endpoint: 'http://localhost:3000',
            getUploadParameters: async (file) => {
                // Request a presigned URL from your backend
                const {
                    data: { url },
                } = await this._emailService.getUploadParams(file);

                return {
                    method: 'PUT',
                    url,
                    headers: {
                        'Content-Type': file.type,
                    },
                };
            },
        });

        // Handle the upload success event
        this.uppy.on('complete', (result) => {
            console.log('Upload complete:', result.successful);
            this.finishedUpload.emit(
                result.successful!.map(
                    (file) =>
                        new Attachment({
                            name: file.name!,
                            type: Attachment.getAttachmentTypeFromFileType(file.type),
                            url: file.uploadURL!,
                            size: file.size!,
                            metadata: {},
                        })
                )
            );
        });

        this.uppy.on('progress', (progress) => {
            console.log('Upload progress:', progress);
        });

        this.uppy.on('files-added', (files) => {
            console.log('Files added:', files);
            this.filesSelected.emit(
                files.map(
                    (file) =>
                        new Attachment({
                            name: file.name!,
                            type: Attachment.getAttachmentTypeFromFileType(file.type),
                            url: '',
                            metadata: {},
                        })
                )
            );
        });
    }

    // Trigger file selection dialog
    openFilePicker(): void {
        this.fileInput()?.nativeElement.click();
    }

    handleFileInput(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        if (files) {
            Array.from(files).forEach((file) =>
                this.uppy?.addFile({
                    name: file.name,
                    type: file.type,
                    data: file,
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.uppy?.destroy();
        document.getElementById(this.targetContainerId())?.removeEventListener('click', () => {
            this.openFilePicker();
        });
    }

    removeAttachment(attachment: Attachment): void {
        const files = this.uppy?.getFiles();
        const file = files?.find((file) => file.name === attachment.name);
        if (!file) {
            return;
        }
        this.uppy?.removeFile(file.id);
    }
}
