export enum SystemLabel {
    INBOX = 'inbox',
    UNREAD = 'unread',
    FOLLOWED = 'followed',
    ARCHIVED = 'archived',
    TRASH = 'trash',
    SPAM = 'spam',
    SENT = 'sent',
    DRAFT = 'draft',
    NOTIFICATION = 'notification',
    NEWSLETTER = 'Newsletter',
    AGENDA = 'Agenda',
    COLD_EMAIL = 'Cold email',
}

export enum DefaultUserLabel {
    Sales = 'Sales',
    Marketing = 'Marketing',
    HR = 'HR',
    Finance = 'Finance',
    Admin = 'Admin',
    Clients = 'Clients',
    Investors = 'Investors',
    Legal = 'Legal',
    Tools = 'Tools',
    Partnership = 'Partnership',
    Media = 'Media/PR',
}
