import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { HlmAvatarModule } from '@recapp/ui';
import { AvatarPipe } from '../../pipes/avatar.pipe';
import { NgClass } from '@angular/common';

export interface Avatar {
    url?: string;
    fallback: string;
}

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    standalone: true,
    imports: [HlmAvatarModule, AvatarPipe, NgClass],
    providers: [],
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
    readonly size = input<'sm' | 'md'>('md');
    readonly avatars = input.required<Avatar[]>();

    readonly avatarSize = computed(() => {
        const avatarCount = this.avatars().length;
        if (avatarCount > 3) {
            return 2.5;
        }
        return 9 / avatarCount;
    });

    readonly visibleAvatars = computed(() => {
        return this.avatars().slice(0, 4);
    });

    readonly extraAvatarsCount = computed(() => {
        return this.avatars().length > 4 ? this.avatars().length - 3 : 0;
    });

    readonly avatarCount = computed(() => {
        if (this.avatars().length > 3) {
            return 'more';
        }
        return this.avatars().length.toString();
    });
}
