import { ChangeDetectionStrategy, Component, computed, inject, signal } from "@angular/core";
import { HlmButtonDirective, HlmIconComponent, HlmInputModule, HlmMenuComponent, HlmScrollAreaComponent, provideIcons } from "@recapp/ui";
import { availableColors } from "@web/app/shared/helpers/color";
import { CustomDialogService } from "@web/app/shared/services/custom-dialog.service";
import * as icons from "@ng-icons/lucide";
import { View } from "@web/app/modules/inbox/models/view.model";
import { ViewFilterOperator, ViewGroupByKey, ViewGroupByOrder } from "@recapp/dto";
import { InboxContext } from "@web/app/modules/inbox/contexts/inbox.context";
import { NgStyle, NgTemplateOutlet } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { BrnMenuTriggerDirective } from "@spartan-ng/ui-menu-brain";
import { ViewMutations } from "@web/app/modules/inbox/contexts/view.mutations";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewsContext } from "@web/app/modules/inbox/contexts/views.context";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-create-view-dialog',
    templateUrl: './create-view-dialog.html',
    imports: [HlmIconComponent, HlmButtonDirective, HlmScrollAreaComponent, HlmInputModule, NgStyle, TranslateModule, HlmMenuComponent, BrnMenuTriggerDirective, NgTemplateOutlet],
    providers: [
        provideIcons(icons)
    ]
})
export class CreateViewDialogComponent {
    readonly data = inject<{view: View}>(MAT_DIALOG_DATA)
    readonly dialogService = inject(CustomDialogService);
    readonly inboxContext = inject(InboxContext);
    readonly viewContext = inject(ViewsContext)
    readonly viewMutations = inject(ViewMutations)
    readonly selectedColor = signal<string>(availableColors[0]);
    readonly router = inject(Router);
    readonly activatedRoute = inject(ActivatedRoute);

    readonly selectedIcon = signal<string>(
        Object.keys(icons)[Math.floor(Math.random() * Object.keys(icons).length)]
    );
    readonly AVAILABLE_COLORS = availableColors;
    readonly view = signal( this.data?.view ?? new View({
        color: availableColors[0],
        icon: Object.keys(icons)[Math.floor(Math.random() * Object.keys(icons).length)],
        filters: {
            fields: [],
            operator: ViewFilterOperator.And
        },
        groupBy: [{
            key: ViewGroupByKey.Priority,
            order: ViewGroupByOrder.Asc
        }],
        isSystem: false,
        name: '',
        userId: this.inboxContext.currentUserId()!,
        description: ''
    }))

    readonly icons = Object.keys(icons);

    readonly canCreateView = computed(() => this.view().name.length > 0 && this.viewContext.views().findIndex((v) => v.name.toLowerCase() === this.view().name.toLowerCase()) === -1);

    readonly isUpdate = computed(() => !!this.data?.view);

    readonly canUpdateView = computed(() => this.view().name.length > 0  && this.viewContext.views().findIndex((v) => v.name.toLowerCase() === this.view().name.toLowerCase() && v.id !== this.view().id) === -1);

    onViewChange(field: 'name' | 'description' | 'color' | 'icon', value: EventTarget | string ) {
        
        this.view.update((v) => v.copyWith({ [field]: typeof value === 'string' ? value : (value as HTMLInputElement).value }));
    }

    close() {
        this.dialogService.closeAll();
    }

    createView() {
        if(this.data?.view) {
            this.viewMutations.updateViewMutation.mutate(this.view())
            this.close();
            return;
        }
        this.viewMutations.createViewMutation.mutate(this.view())
        this.router.navigate(['mail','views', this.view().id], );
        this.close();
    }
}