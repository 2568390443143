/**
 * @packageDocumentation
 * @module api.functional.api.threads.labels
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type { UpdateThreadsLabelsDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

/**
 * @controller ThreadsController.updateLabels
 * @path PUT /api/threads/labels
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateLabels(
  connection: IConnection,
  { threadIds, labelsToAdd, labelsToRemove }: updateLabels.Input,
): Promise<updateLabels.Output> {
  return !!connection.simulate
    ? updateLabels.simulate(connection, {
        threadIds,
        labelsToAdd,
        labelsToRemove,
      })
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...updateLabels.METADATA,
          template: updateLabels.METADATA.path,
          path: updateLabels.path(),
        },
        { threadIds, labelsToAdd, labelsToRemove },
      );
}
export namespace updateLabels {
  export type Input = Resolved<UpdateThreadsLabelsDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "PUT",
    path: "/api/threads/labels",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/threads/labels";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    { threadIds, labelsToAdd, labelsToRemove }: updateLabels.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert({ threadIds, labelsToAdd, labelsToRemove }));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
