import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";
import { lucideX } from "@ng-icons/lucide";
import { HlmIconComponent, provideIcons } from "@recapp/ui";

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HlmIconComponent,
        NgStyle
    ],
    providers: [
        provideIcons({
            lucideX
        })
    ]
})
export class ChipComponent {
    readonly canDelete = input(false);
    readonly bgColor = input<string>();
    readonly name = input<string>();
    readonly value = input<any>();

    readonly onDelete = output<any>();
}