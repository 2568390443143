import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Thread } from "@web/app/modules/inbox/models/thread.model";
import { NewEmailBodyComponent } from "@web/app/modules/inbox/views/new-email/components/new-email-body/new-email-body.component";
import { NewEmailSideComponent } from "@web/app/modules/inbox/views/new-email/components/new-email-side/new-email-side.component";

@Component({
    selector: 'app-new-email-modal',
    templateUrl: './new-email-modal.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NewEmailBodyComponent,
        NewEmailSideComponent
    ],
    providers: []
})
export class NewEmailModalComponent {
    readonly dialogData = inject<{
        thread: Thread
    }>(MAT_DIALOG_DATA)
}