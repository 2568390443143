export enum EmailState {
    READ = 'READ',
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED',
    TRASHED = 'TRASHED',
    DRAFT = 'DRAFT',
}

export enum Priority {
    URGENT = 'urgent',
    TODO = 'todo',
    READ_ONLY = 'read_only',
    UNKNOWN = 'unknown',
}

export enum PriorityLevel {
    URGENT = 1,
    TODO = 2,
    READ_ONLY = 3,
    UNKNOWN = 4,
}

export const priorities: Record<
    Priority,
    {
        name: string;
        level: PriorityLevel;
        color: string;
    }
> = {
    [Priority.URGENT]: {
        name: 'urgent',
        level: PriorityLevel.URGENT,
        color: '#F18B8B',
    },
    [Priority.TODO]: {
        name: 'todo',
        level: PriorityLevel.TODO,
        color: '#EBBF07',
    },
    [Priority.READ_ONLY]: {
        name: 'read_only',
        level: PriorityLevel.READ_ONLY,
        color: '#48BDF8',
    },
    [Priority.UNKNOWN]: {
        name: 'unknown',
        level: PriorityLevel.UNKNOWN,
        color: '#999999',
    },
};

export enum AttachmentType {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    CALENDAR_EVENT = 'calendar_event',
    DOCUMENT = 'document',
    LINK = 'link',
    PDF = 'pdf',
    ARCHIVE = 'archive',
}

export enum SyncStatus {
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    NOT_SYNCED = 'NOT_SYNCED',
}
