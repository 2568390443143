import { Types } from 'mongoose';
export * from './return-types';
export * from './pagination';

export type ID = Types.ObjectId;

export type TApiResult<T = never, U = never> = ([T] extends [never]
    ? {}
    : { data: T }) &
    ([U] extends [never] ? {} : { metadata?: U });

export class ApiResult<T = never, U = never> {
    data: T;
    metadata?: U;

    constructor(data: T, metadata?: U) {
        this.data = data;
        if (metadata) this.metadata = metadata;
    }
}
