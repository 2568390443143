import { inject, Injectable } from '@angular/core';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { View } from '../models/view.model';
import { toast } from 'ngx-sonner';
import { ViewService } from '@web/app/modules/inbox/services/views.service';
import { ViewsContext } from '@web/app/modules/inbox/contexts/views.context';

@Injectable({
    providedIn: 'root',
})
export class ViewMutations {
    private readonly _viewService = inject(ViewService);
    private readonly _viewsContext = inject(ViewsContext);

    readonly createViewMutation = injectMutation(() => ({
        mutationFn: (params: View) => this._viewService.createView(params),
        onMutate: (view) => {
            this._viewsContext.views.update((views) => {
                return [...views, view];
            });
            this._viewsContext.currentViewId.set(view.id);
        },
        onError: (_error, _addedView, context: any) => {
            this._viewsContext.views.set(context?.previousData);
            toast.error('Error creating view');
        },
    }));

    readonly deleteViewMutation = injectMutation(() => ({
        mutationFn: (params: string) => this._viewService.deleteView(params),
        onMutate: (viewId) => {
            this._viewsContext.views.update((views) => {
                return views.filter((view) => view.id !== viewId);
            });
        },
        onError: (_error, _addedView, context: any) => {
            this._viewsContext.views.set(context?.previousData);
            toast.error('Error deleting view');
        },
    }));

    readonly updateViewMutation = injectMutation(() => ({
        mutationFn: (params: View) => this._viewService.updateView(params),
        onMutate: (view) => {
            this._viewsContext.views.update((views) => {
                return views.map((v) => (v.id === view.id ? view : v));
            });
        },
        onError: (_error, _addedView, context: any) => {
            this._viewsContext.views.set(context?.previousData);
            toast.error('Error updating view');
        },
    }));
}
