import { inject, Injectable } from '@angular/core';
import { CalendarEventResponseDTO, EmailDTO, ThreadDTO } from '@recapp/dto';
import { ApiResult } from '@recapp/shared-types';
import { TrpcService } from '@web/app/core/services/trpc.service';

@Injectable({
    providedIn: 'root',
})
export class EmailsService {
    private readonly _trpcService = inject(TrpcService);

    updateEmail(emailDto: EmailDTO) {
        return this._trpcService.api.emails.update(this._trpcService.connection, emailDto).then((res) => new ApiResult(res.data));
    }

    createAnswerDraft(emailDto: EmailDTO) {
        return this._trpcService.api.emails.drafts.answer
            .createDraft(this._trpcService.connection, emailDto)
            .then((res) => new ApiResult(res.data));
    }

    deleteEmail(emailId: string) {
        return this._trpcService.api.emails.deleteEmail(this._trpcService.connection, emailId).then((res) => new ApiResult(res.data));
    }

    sendEmail(emailDto: EmailDTO) {
        return this._trpcService.api.emails.send.sendEmail(this._trpcService.connection, emailDto).then((res) => new ApiResult(res.data));
    }

    getUploadParams(file: any) {
        return this._trpcService.api.emails.get_cloud_storage_upload_params
            .getCloudStorageUploadParams(this._trpcService.connection, { file })
            .then((res) => new ApiResult(res.data));
    }

    replyToCalendarEvent(dto: CalendarEventResponseDTO) {
        return this._trpcService.api.emails.calendar.reply
            .replyToCalendarEvent(this._trpcService.connection, dto)
            .then((res) => new ApiResult(res.data));
    }

    upsertDraftEmail(threadDto: ThreadDTO) {
        return this._trpcService.api.emails.drafts.upsert
            (this._trpcService.connection, threadDto)
            .then((res) => new ApiResult(res.data));
    }
}
