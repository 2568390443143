<div class="bg-light-bg p-4 h-full w-full">
    <div class="flex flex-col items-center justify-between h-full gap-y-4">
        @if(isUpdate()) {
            <h4>{{ 'views.create_view_modal.update_title' | translate }}</h4>
        } @else {
            <h4>{{ 'views.create_view_modal.title' | translate }}</h4>
        }
        <div class="flex items-center gap-x-2">
            <div class="p-1 rounded-sm border border-light-bg-darker flex items-center justify-center cursor-pointer" [brnMenuTriggerFor]="colorMenu">
                <div class="h-7 w-7 rounded" [ngStyle]="{
                    backgroundColor: view().color
                }"></div>
            </div>
            <div class="rounded-sm border border-light-bg-darker flex items-center justify-center h-9 w-9 cursor-pointer" [brnMenuTriggerFor]="iconMenu">
                <hlm-icon class="p-1" [ngStyle]="{color: view().color}" [name]="view().icon" ></hlm-icon>
            </div>
            <input type="text" [autofocus]="true" hlmInput placeholder="{{ 'views.create_view_modal.placeholder' | translate }}" class="w-40" [value]="view().name" (input)="onViewChange('name', $event.target!)">
        </div>
        <div class="flex items-center gap-x-2">
            <button (submit)="createView()" [disabled]="isUpdate() ? !canUpdateView() : !canCreateView()" (click)="createView()" hlmBtn>
            @if(isUpdate()) {
                {{'common.update' | translate}}
            } @else {
                {{'common.create' | translate}}
            }
            </button>
            <button (click)="close()" variant="ghost">{{'common.cancel' | translate}}</button>
        </div>
    </div>
</div>
<ng-template #iconMenu>
    <hlm-menu class="w-fit">
        <hlm-scroll-area class="h-[300px] min-w-[250px] w-max">
            <div class="grid grid-cols-6 gap-2">
                @for (icon of icons; track $index) {
                    <hlm-icon class="cursor-pointer hover:scale-125 transition-all" (click)="onViewChange('icon', icon)" [name]="icon" size="sm"></hlm-icon>
                }
            </div>
        </hlm-scroll-area>
    </hlm-menu>
</ng-template>
<ng-template #colorMenu>
    <hlm-menu class="w-fit">
        <hlm-scroll-area class="h-[300px] min-w-[250px] w-max">
            <div class="grid grid-cols-6 gap-3">
                @for (color of AVAILABLE_COLORS; track $index) {
                    <div class="h-7 w-7 rounded cursor-pointer hover:scale-125 transition-all" [ngStyle]="{
                        backgroundColor: color
                    }" (click)="onViewChange('color', color)"></div>
                }
            </div>
        </hlm-scroll-area>
    </hlm-menu>    
</ng-template>
