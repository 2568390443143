import { AttachmentType } from '@recapp/shared-types';

export interface AttachmentDTO<Metadata = Record<string, any>> {
    id: string;
    name: string;
    type: AttachmentType;
    url: string;
    size?: number;
    metadata?: Metadata;
    readonly createdAt: string;
    readonly updatedAt: string;
}

export interface GetCloudStorageUploadParamsQueryResult {
    method: 'put';
    url: string;
}

export type CalendarEvent = {
    socialId: string;
    summary: string;
    location: string;
    meetLink: string;
    status: CalendarEventResponseStatus | null;
    organizer: {
        email: string;
        CN?: string;
    };
    start: {
        dateTime: string;
        timeZone: string;
    };
    end: {
        dateTime: string;
        timeZone: string;
    };
    attendees: {
        email: string;
        responseStatus: string;
    }[];
};

export enum CalendarEventResponseStatus {
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    MAYBE = 'MAYBE',
}
