<div class="flex flex-col">
    @for (view of viewsContext.views(); track $index) {
        <app-nav-item
            [icon]="view.icon"
            [text]="view.isSystem ? ('views.' + view.name | translate) : view.name"
            [iconColor]="view.color"
            [routeName]="'/mail/views/' + view.id"
            (click)="$event.stopPropagation()">
            @if (sidenavContext.opened()) {
                <hlm-icon
                    class="hidden min-h-fit min-w-fit cursor-pointer rounded-sm p-0.5 transition-all hover:bg-light"
                    name="lucideMoreHorizontal"
                    size="sm"
                    [brnMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"></hlm-icon>
            }

            <ng-template #menu>
                <hlm-menu class="w-64">
                    <button class="cursor-pointer" hlmMenuItem (click)="updateView(view)">
                        {{ 'common.edit' | translate }}
                    </button>
                    <button class="cursor-pointer" hlmMenuItem (click)="deleteView(view.id)">
                        {{ 'common.delete' | translate }}
                    </button>
                </hlm-menu>
            </ng-template>
        </app-nav-item>
    }

    @if (sidenavContext.opened()) {
        <button class="nav-item mb-2 flex h-7 min-h-[16px] cursor-pointer items-center gap-x-2 rounded-sm px-3 text-light" hlmBtn (click)="openCreateViewDialog()">
            <hlm-icon class="!flex" name="lucidePlusCircle" size="sm"></hlm-icon>
            <span class="truncate text-sm">
                {{ 'sidebar.views.new_view' | translate }}
            </span>
        </button>
    }
</div>
