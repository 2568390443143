import { UserDTO, Signature, Contact } from '@recapp/dto';
import { BaseEntity, EntityConstructor, IBaseEntity, Language } from '@recapp/shared-types';
import { UserAiSettings } from '../../../../../../../packages/dto/src/users/user.dto';
import { Avatar } from '@web/app/shared/components/avatar/avatar.component';

export class User extends BaseEntity {
    email: string;
    password?: string;
    firstname: string;
    lastname: string;
    language?: string;
    aiSettings?: UserAiSettings;
    signatures?: Signature[];
    contacts?: Contact[];

    constructor(data: EntityConstructor<User> & IBaseEntity) {
        super(data);
        this.email = data.email;
        this.password = data.password;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.language = data.language || Language.FR;
        this.aiSettings = data.aiSettings;
        this.signatures = data.signatures || [];
        this.contacts = data.contacts || [];
    }

    static fromDto(dto: UserDTO) {
        return new User({
            id: dto.id,
            email: dto.email,
            firstname: dto.firstname,
            lastname: dto.lastname,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
            aiSettings: dto.aiSettings,
            contacts: dto.contacts,
            signatures: dto.signatures,
        });
    }

    getFullName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    getAvatar(): Avatar {
        return {
            fallback: this.firstname.charAt(0).toUpperCase(),
        };
    }

    getDefaultSignature(): string {
        return this.signatures?.find((signature) => signature.isDefault)?.signature ?? '';
    }
}
