import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OutputData } from "@editorjs/editorjs";
import { lucideMoreHorizontal, lucideSparkles } from "@ng-icons/lucide";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { ActionItem } from "@recapp/dto";
import { HlmButtonDirective, HlmIconComponent, HlmInputModule, provideIcons } from "@recapp/ui";
import { NewEmailContext } from "@web/app/modules/inbox/views/new-email/context/new-email.context";
import { selectCurrentUser } from "@web/app/modules/users/store/user.reducer";
import { AvatarComponent } from "@web/app/shared/components/avatar/avatar.component";
import { TextEditorComponent } from "@web/app/shared/components/text-editor/text-editor.component";

@Component({
    selector: 'app-new-email-side',
    templateUrl: './new-email-side.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HlmIconComponent,
        TextEditorComponent,
        TranslateModule,
        MatTooltipModule,
        AvatarComponent,
        HlmButtonDirective,
        HlmInputModule
    ],
    providers: [
        provideIcons({
            lucideMoreHorizontal,
            lucideSparkles
        })
    ]
})
export class NewEmailSideComponent {
    private readonly _store = inject(Store);
    private readonly _newEmailContext = inject(NewEmailContext);
    readonly threadId = input.required<string>();

    readonly currentUser = this._store.selectSignal(selectCurrentUser);
    readonly thread = computed(() => this._newEmailContext.draftThreads().find((draftEmail) => draftEmail.id === this.threadId())!);

    readonly actionItems = computed<OutputData>(() => ({
            blocks: [
                {
                    type: 'checklist',
                    data: {
                        items: this.thread()?.actionItems?.map((actionItem) => ({
                            text: actionItem.content,
                            checked: actionItem.completed,
                        })),
                    },
                },
            ],
        }));

    actionItemsChanged(value: OutputData): void {
        const newActions: ActionItem[] = value.blocks[0].data.items.map((item: any) => ({
            content: item.text,
            completed: item.checked,
        }));
        console.log(newActions);
    }
}