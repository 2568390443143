<div class="flex items-center gap-x-2 gap-y-2" [ngClass]="{
    'flex-wrap': wrap
}">
    @if (attachments().length && attachments()[0].type === AttachmentType.CALENDAR_EVENT) {
        <brn-toggle-group  [multiple]="false" [nullable]="true" [value]="calendarEventDetails()!.status" class="flex gap-x-1 rounded-lg border" (click)="$event.stopPropagation()">
            <button
                class="rounded-none rounded-bl-[7px] rounded-tl-[7px] data-[state=on]:bg-success data-[state=on]:text-white"
                brnToggle
                [value]="CalendarEventStatus.ACCEPTED"
                (click)="onValueChange(CalendarEventStatus.ACCEPTED)"
                hlm
                [size]="'sm'">
                {{ 'common.yes' | translate }}
            </button>
            <button 
                [value]="CalendarEventStatus.DECLINED"
                (click)="onValueChange(CalendarEventStatus.DECLINED)"
            class="rounded-none data-[state=on]:bg-error data-[state=on]:text-white" brnToggle hlm [size]="'sm'">
                {{ 'common.no' | translate }}
            </button>
            <button
                [value]="CalendarEventStatus.MAYBE"
                (click)="onValueChange(CalendarEventStatus.MAYBE)"
                class="rounded-none rounded-br-[7px] rounded-tr-[7px] data-[state=on]:bg-light data-[state=on]:text-white"
                brnToggle
                hlm
                [size]="'sm'">
                {{ 'common.maybe' | translate }}
            </button>
        </brn-toggle-group>
    } @else {
        @for (attachment of attachments(); track $index) {

                <div
                    class="flex max-w-60 cursor-pointer items-center gap-x-2 rounded-full border border-light-bg-darker bg-white px-5 py-2 text-black transition-all hover:bg-light-bg-darker relative"
                    [ngClass]="{'opacity-40': attachment.url === '', 'curosor-not-allowed': attachment.url === ''}"
                    (click)="attachment.url !== '' && openPreviewModal(attachment); $event.stopPropagation()">
                                
                @if (
                    attachment.url === ''
                ) {
                    <!-- show loading spinner -->
                     
                        <hlm-spinner class="" size="xs"></hlm-spinner>
                     
                }
                    @if (attachment.type === AttachmentType.PDF) {
                        <img alt="pdf file" ngSrc="/assets/icons/pdf.svg" width="14" height="14" />
                    } @else {
                        <hlm-icon class="text-attachment-color" size="xs" [name]="attachment.getAttachmentIcon()"></hlm-icon>
                    }
                    <span class="truncate max-w-[50px] text-[10px]"> {{ attachment.name }} </span>

                @if(canRemoveAttachments()) {
                    
                        <button hlmBtn variant="ghost" size="xs" class="p-1 h-fit">
                            <hlm-icon class="cursor-pointer text-black" (click)="onRemoveAttachment.emit(attachment); $event.stopPropagation()" name="lucideX" size="xs"></hlm-icon>
                        </button>
                    
                }
                </div>
                


        }
    }
</div>
