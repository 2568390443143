<!-- @if (avatars().length === 1) { -->
    <hlm-avatar
        class="h-9 w-9 rounded-sm"
        [ngClass]="{
            'h-[28px] w-[28px]': size() === 'sm'
        }">
        <img
            class="h-9 w-9 rounded-sm"
            hlmAvatarImage
            [ngClass]="{
                'h-[28px] w-[28px]': size() === 'sm'
            }"
            [src]="avatars()[0].url" />
        <img
            class="h-9 w-9 rounded-sm border bg-white p-1"
            hlmAvatarFallback
            [ngClass]="{
                'h-[28px] w-[28px]': size() === 'sm'
            }"
            [src]="avatars()[0].fallback | avatar" />
    </hlm-avatar>
<!-- } @else {
    <div class="avatar-grid" [attr.data-count]="avatarCount()">
        @for (avatar of visibleAvatars(); track $index) {
            <img class="avatar rounded-sm border bg-white" hlmAvatarFallback [src]="avatar.fallback | avatar" />
        }
    </div>
} -->
