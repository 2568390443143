import { z } from 'zod';

// Define the Zod schema
export const updateUserSchema = z.object({
    username: z.string().min(3).max(20).optional(),
    email: z.string().email().optional(),
    password: z.string().min(8).optional(),
    firstname: z.string().min(3).optional(),
    lastname: z.string().min(3).optional(),
});

// Define the type for UpdateeUserDTO
export interface UpdateUserDTO extends z.infer<typeof updateUserSchema> {}
