import { ChangeDetectionStrategy, Component, computed, inject, input, model, signal, viewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { lucideMaximize2, lucideMinus, lucideMoreHorizontal, lucidePaperclip, lucidePencil, lucideTrash, lucideWand2, lucideX } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmAvatarComponent, HlmButtonDirective, HlmIconComponent, provideIcons } from '@recapp/ui';
import { Attachment } from '@web/app/modules/inbox/models/attachment.model';
import { Thread } from '@web/app/modules/inbox/models/thread.model';
import { EmailsService } from '@web/app/modules/inbox/services/emails.service';
import { NewEmailContext } from '@web/app/modules/inbox/views/new-email/context/new-email.context';
import { ChipComponent } from '@web/app/shared/components/chip/chip.component';
import { FileUploaderComponent } from '@web/app/shared/components/file-uploader/file-uploader.component';
import { TextEditorComponent } from '@web/app/shared/components/text-editor/text-editor.component';
import { AttachmentsPreviewComponent } from '@web/app/shared/components/thread-view-modal/components/thread-view-modal-side/components/attachments-preview/attachments-preview.component';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';
import { toast } from 'ngx-sonner';
import { debounceTime, skip } from 'rxjs';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AvatarComponent } from '@web/app/shared/components/avatar/avatar.component';
import { Contact } from '@recapp/dto';
import { ThreadsService } from '@web/app/shared/components/thread-view-modal/services/threads.service';


@Component({
    selector: 'app-new-email-body',
    templateUrl: './new-email-body.component.html',
    styleUrl: './new-email-body.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ChipComponent,
        TextEditorComponent,
        AttachmentsPreviewComponent,
        FileUploaderComponent,
        TranslateModule,
        MatTooltipModule,
        HlmIconComponent,
        ReactiveFormsModule,
        HlmButtonDirective,
        MatAutocompleteModule,
        MatInputModule,
        AvatarComponent
    ],
    providers: [
        provideIcons({
            lucideMaximize2,
            lucideMinus,
            lucideX,
            lucidePencil,
            lucideMoreHorizontal,
            lucideWand2,
            lucidePaperclip,
            lucideTrash
        }),
    ],
})
export class NewEmailBodyComponent {
    readonly threadId = input.required<string>();
    readonly isInModal = input(false);
    readonly expanded = model<boolean>(true);
    readonly fileUploaderRef = viewChild(FileUploaderComponent);

    readonly newEmailContext = inject(NewEmailContext);
    private readonly _emailService = inject(EmailsService);
    private readonly _dialog = inject(CustomDialogService);
    private readonly _threadService = inject(ThreadsService);
    
    readonly emailFormControl = new FormControl('', [Validators.email, Validators.required]);
    readonly subjectFormControl = new FormControl('', [Validators.required]);
    readonly contentFormControl = new FormControl('', [Validators.required]);

    readonly thread = computed(() => this.newEmailContext.draftThreads().find((draftEmail) => draftEmail.id === this.threadId())!);

    readonly editorId = 'new-email-editor' + new Date().getTime();
    readonly user = computed(() => this.newEmailContext.currentUser()!);

    readonly filteredContacts = signal<Contact[]>(this.user().contacts ?? [])

    readonly isNewOption = computed(() => {
        const email = this.emailFormControl.value ?? '';
        return !this.filteredContacts().some((contact) => this._normalizeString(contact.email) === this._normalizeString(email)) && email !== '' && this.thread().getDestinations().findIndex((destination) => this._normalizeString(destination.emailAdress) === this._normalizeString(email)) === -1;
    });

    ngOnInit(): void {
        this.subjectFormControl.valueChanges.pipe(debounceTime(300)).subscribe({
            next: (value) => {
                this.newEmailContext.updateSubject(this.threadId(), value!);
            },
        });
        this.contentFormControl.valueChanges.pipe(skip(1), debounceTime(300)).subscribe({
            next: (value) => {
                this.newEmailContext.updateContent(this.threadId(), value!);
            },
        });

        this.emailFormControl.valueChanges.subscribe({
            next: (value) => {
                if(!value) {
                    this.filteredContacts.set(this.user().contacts ?? []);
                    return;
                }
                this.filteredContacts.set(this.user().contacts?.filter((contact) => (
                    this._normalizeString(contact.email).includes(this._normalizeString(value)) || 
                    this._normalizeString(contact.name).includes(this._normalizeString(value))) &&
                    !this.thread().emails[0].destination.some((destination) => this._normalizeString(destination.emailAdress) === this._normalizeString(contact.email))
            
            ) ?? []);
            },
        });
    }

    close(shouldDelete = false): void {
        this._dialog.closeAll();
        const thread = this.thread();
        this.newEmailContext.removeDraftEmail(this.threadId());
        if (thread.getLastEmail().isEmpty() || shouldDelete) {
            this.removeDraft();
        }
    }

    removeDraft(): void {
        this.close(true);
    }

    addDestination(value: MatAutocompleteSelectedEvent): void {
        if (this.emailFormControl.valid) {
            this.newEmailContext.addDestination(this.threadId(), value.option.value);
            this.emailFormControl.reset();
        }
    }

    removeDestination(event: Event | null, destinationEmail?: string): void {
        const inputText = (event?.target as HTMLInputElement)?.value;
        if (destinationEmail) {
            this.newEmailContext.removeDestination(this.threadId(), destinationEmail);
        } else {
            if (inputText === '') {
                this.newEmailContext.removeDestination(
                    this.threadId(),
                    this.thread().emails[0].destination[this.thread().emails[0].destination.length - 1].emailAdress
                );
            }
        }
    }

    toggleExpanded(): void {
        this.expanded.set(!this.expanded());
    }

    send(): void {
        const thread = Thread.fromDto({...this.thread().toDto()});
        thread.emails[0].content = `${thread.emails[0].content} <br><br> ${this.user().getDefaultSignature()}`;
        let canceled = false;
        toast.info('Sending email...', {
            duration: 5000,
            action: {
                label: 'Cancel',
                onClick: () => {
                    canceled = true;
                    this.newEmailContext.addDraftEmail(thread);
                },
            }
        });

        setTimeout(() => {
            if(canceled)
                return;
            
            this._emailService.sendEmail(thread.getLastEmail().toDto());
        }, 5000);
        this.close();
    }

    onFilesSelected(files: Attachment[]): void {
        this.newEmailContext.addAttachments(this.threadId(), files);
    }

    onEditorChange(value: string): void {
        this.contentFormControl.setValue(value);
    }

    removeAttachment(attachment: Attachment): void {
        this.newEmailContext.removeAttachment(this.threadId(), attachment.id);
        this.fileUploaderRef()?.removeAttachment(attachment);
    }

    aiAutoCompleteCallback = (text: string) => {
        return this._threadService.completeSentence({ thread: this.thread()!.toDto(), text }).then((res) => res.data.suggestion);
    }

    private _normalizeString(value: string | undefined): string {
        if (!value) {
            return '';
        }
        return value.trim().toLowerCase();
    }
}
