import { inject, Injectable, signal } from '@angular/core';
import { NewEmailContext } from '@web/app/modules/inbox/views/new-email/context/new-email.context';

@Injectable({ providedIn: 'root' })
export class SidenavContext {
    readonly opened = signal(false);
    private readonly _newEmailContext = inject(NewEmailContext);
    

    createNewEmail() {
        this._newEmailContext.addDraftEmail();
    }
}
