import { Injectable, inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@web/app/modules/auth/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ShouldRedirectToAuthGuard implements CanActivate {
    private readonly _router: Router = inject(Router);
    private readonly _authService: AuthService = inject(AuthService);

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const previousUrl = state.url;
         if (previousUrl.startsWith('/auth/login') && !this._authService.isAuthenticated()) {
            this._router.navigate(['/auth/login']);
            return false;
        } else if (this._authService.isAuthenticated()) {
            this._router.navigate(['/mail/inbox']);
            return false;
        }
        return true;
    }
}
