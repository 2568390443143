import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, contentChild, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import * as icons from '@ng-icons/lucide';
import { HlmIconComponent, provideIcons } from '@recapp/ui';
import { SidenavContext } from '@web/app/core/layout/sidenav/context/sidenav.context';

@Component({
    standalone: true,
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, RouterLinkActive, HlmIconComponent, NgClass],
    providers: [provideIcons(icons)],
})
export class NavItemComponent {
    readonly icon = input<string>();
    readonly iconColor = input<string>();
    readonly text = input<string>();
    readonly routeName = input<string>();
    readonly section = input<string>();
    readonly sidenavContext = inject(SidenavContext);
}
