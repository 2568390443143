import { ViewGroupByKey, ViewGroupByOrder, ViewFilters, ViewFilterComparator, ViewDTO, ViewFilterOperator } from '@recapp/dto';
import { BaseEntity, EntityConstructor } from '@recapp/shared-types';
import { isArray } from '@recapp/utils';
import { isNotNil } from '@web/app/shared/helpers/is-not-nil';


export type ViewProps = EntityConstructor<View>;

export class View extends BaseEntity {
    name: string;
    description?: string;
    color: string;
    icon: string;
    groupBy: {
        key: ViewGroupByKey;
        order: ViewGroupByOrder;
    }[];
    filters: {
        operator: ViewFilterOperator;
        fields: {
            key: ViewFilters;
            value: {
                comparator: ViewFilterComparator;
                value: any | any[];
            };
        }[];
    };
    userId: string;
    isSystem: boolean;

    constructor(props: ViewProps) {
        super(props);
        this.name = props.name;
        this.description = props.description;
        this.color = props.color;
        this.icon = props.icon;
        this.groupBy = props.groupBy;
        this.filters = props.filters;
        this.userId = props.userId;
        this.isSystem = props.isSystem;
    }

    toDto(): ViewDTO {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            color: this.color,
            icon: this.icon,
            groupBy: this.groupBy,
            filters: this.filters,
            userId: this.userId,
            isSystem: this.isSystem,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }

    static fromDto(dto: ViewDTO): View {
        return new View({
            id: dto.id,
            name: dto.name,
            description: dto.description,
            color: dto.color,
            icon: dto.icon,
            groupBy: dto.groupBy,
            filters: dto.filters,
            userId: dto.userId,
            isSystem: dto.isSystem,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
        });
    }

    isGroupedByPriority(): boolean {
        return this.groupBy.some((group) => group.key === ViewGroupByKey.Priority);
    }

    isGroupedByAuthor(): boolean {
        return this.groupBy.some((group) => group.key === ViewGroupByKey.Sender);
    }

    isGroupedByLabel(): boolean {
        return this.groupBy.some((group) => group.key === ViewGroupByKey.Label);
    }

    isGroupedByDate(): boolean {
        return this.groupBy.some((group) => group.key === ViewGroupByKey.Date);
    }

    setViewGrouping(grouping: ViewGroupByKey): View {
        return this.copyWith({
            groupBy: [
                {
                    key: grouping,
                    order: ViewGroupByOrder.Asc,
                },
            ],
        });
    }

    copyWith(props: Partial<ViewProps>): View {
        return new View({
            ...this,
            ...props,
        });
    }

    getGroupByKeys(): ViewGroupByKey[] {
        return this.groupBy.map((group) => group.key);
    }

    getFilterByKeyValuesAsQueryParams(): string {
        const params = this.filters.fields.filter((field) => isNotNil(field.key) && isNotNil(field.value.value) && isNotNil(field.value.comparator)).map((field) => `operator=${this.filters.operator}${field.key}=${isArray(field.value.value) ? field.value.value.join(',') : field.value.value}`).join('&')
        return params;
    }

    getSortDirection(): ViewGroupByOrder {
        return this.groupBy[0].order;
    }
}
