import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, signal, viewChild } from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import { lucideImage, lucideLink, lucideMusic, lucidePaperclip, lucideVideo, lucideX } from '@ng-icons/lucide';
import { AttachmentType } from '@recapp/shared-types';
import {
    HlmButtonDirective,
    HlmIconComponent,
    HlmToggleGroupDirective,
    HlmToggleDirective,
    BrnToggleGroupComponent,
    BrnToggleDirective,
    HlmSpinnerComponent,
    HlmToggleGroupModule,
    BrnButtonToggleChange,
} from '@recapp/ui';
import { Attachment } from '@web/app/modules/inbox/models/attachment.model';
import { ApplyPurePipe } from '@web/app/shared/pipes/apply-fn.pipe';
import { CustomDialogService } from '@web/shared/services/custom-dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentPreviewModalComponent } from '@web/app/shared/components/attachment-preview-modal/attachment-preview-modal.component';
import { CalendarEvent, CalendarEventResponseStatus } from '@recapp/dto';
import { EmailsService } from '@web/app/modules/inbox/services/emails.service';

@Component({
    selector: 'app-attachments-preview',
    templateUrl: './attachments-preview.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HlmIconComponent,
        ApplyPurePipe,
        HlmToggleGroupModule,
        NgOptimizedImage,
        HlmButtonDirective,
        HlmToggleGroupDirective,
        HlmToggleDirective,
        BrnToggleGroupComponent,
        BrnToggleDirective,
        TranslateModule,
        NgClass,
        HlmSpinnerComponent
    ],
    providers: [
        provideIcons({
            lucidePaperclip,
            lucideImage,
            lucideVideo,
            lucideLink,
            lucideMusic,
            lucideX
        }),
    ],
})
export class AttachmentsPreviewComponent implements OnInit {
    readonly attachments = input.required<Attachment[]>();
    readonly wrap = input<boolean>(false);
    readonly canRemoveAttachments = input<boolean>(false);
    readonly onRemoveAttachment = output<Attachment>(); 
    readonly onRsvpChange = output<CalendarEventResponseStatus | undefined>();
    readonly toggleGroupRef = viewChild(BrnToggleGroupComponent);
    
    private readonly _dialogRef = inject(CustomDialogService);
    
    readonly groupedAttachmentsByType = computed(() =>
        this.attachments().reduce(
            (acc, attachment) => {
                if (!acc[attachment.type]) {
                    acc[attachment.type] = [];
                }
                acc[attachment.type].push(attachment);
                return acc;
            },
            {} as Record<AttachmentType, Attachment[]>
        )
    );

    
    readonly AttachmentType = AttachmentType;
    
    readonly CalendarEventStatus = CalendarEventResponseStatus;
    
    readonly calendarEventDetails = computed<CalendarEvent | null>(() => this.attachments()[0]?.isCalendarEvent() ? this.attachments()[0].metadata as CalendarEvent : null);    
    
    readonly rsvpStatus = signal<CalendarEventResponseStatus | undefined>(undefined);

    
    ngOnInit(): void {
        setTimeout(() => {
            this.toggleGroupRef()?.writeValue(this.calendarEventDetails()?.status);
            this.rsvpStatus.set(this.calendarEventDetails()?.status ? this.calendarEventDetails()!.status! : undefined)
        }, 0);
    }

    getObjectKeys = (obj: Record<string, any>): string[] => Object.keys(obj);
    
    
    openPreviewModal(attachment: Attachment): void {
        this._dialogRef.open(AttachmentPreviewModalComponent, {
            width: '100vw',
            height: '100vh',
            panelClass: 'dialog-panel',
            data: {
                attachments: this.attachments(),
                attachmentIndex: this.attachments().indexOf(attachment),
            },
        });
    }

    getAttachmentIcon = (type: AttachmentType): string => {
        switch (type) {
            case AttachmentType.IMAGE:
                return 'lucideImage';
            case AttachmentType.VIDEO:
                return 'lucideVideo';
            case AttachmentType.AUDIO:
                return 'lucideMusic';
            case AttachmentType.DOCUMENT:
                return 'lucidePaperclip';
            case AttachmentType.LINK:
                return 'lucideLink';
            default:
                return 'lucidePaperclip';
        }
    };

    getAttachmentsLength = (attachmentsType: AttachmentType): number => this.groupedAttachmentsByType()[attachmentsType].length;

    onValueChange(status: CalendarEventResponseStatus): void {
        this.onRsvpChange.emit(this.rsvpStatus() !== status ? status : undefined);
    }
}
