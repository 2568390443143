import { inject, Injectable, signal, viewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { SystemLabel } from "@recapp/shared-types";
import { uniqBy } from "@recapp/utils";
import { LabelQueries } from "@web/app/modules/inbox/contexts/label.queries";
import { Attachment } from "@web/app/modules/inbox/models/attachment.model";
import { EmailAuthor } from "@web/app/modules/inbox/models/email.model";
import { Thread } from "@web/app/modules/inbox/models/thread.model";
import { NewEmailModalComponent } from "@web/app/modules/inbox/views/new-email/components/new-email-modal/new-email-modal.component";
import { selectUser, UserState } from "@web/app/modules/users/store/user.reducer";
import { CustomDialogService } from "@web/app/shared/services/custom-dialog.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NewEmailContext {
    private readonly _dialogRef = inject(CustomDialogService);
    private readonly _store = inject(Store<UserState>);
    private readonly _labelQueries = inject(LabelQueries);
    
    readonly currentUser = this._store.selectSignal(selectUser);

    readonly draftThreads = signal<Thread[]>([]);

    readonly threadChanged$ = new Subject<Thread>();

    addDraftEmail(canceledThread?: Thread) {
        this.draftThreads.update(draftThreads => [...draftThreads, canceledThread ? canceledThread : Thread.createDraftThread({
            author: new EmailAuthor({
                emailAdress: this.currentUser()!.email,
                isVerified: true,
                name: this.currentUser()!.getFullName() ?? '',
                id: this.currentUser()!.id
            }),
            userId: this.currentUser()!.id,
            draftLabel: this._labelQueries.userLabels().find(label => label.name === SystemLabel.DRAFT)!,
        })])
    }

    removeDraftEmail(threadId: string) {
        this.draftThreads.update(draftThreads => {
            const x = draftThreads.filter(draftEmail => draftEmail.id !== threadId);
            return x
        });
    }

    addDestination(threadId: string, destinationEmail: string | EmailAuthor) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        destination: [...thread.emails[0].destination, isEmailAuthor(destinationEmail) ? destinationEmail : new EmailAuthor({
                            emailAdress: destinationEmail,
                            isVerified: false,
                            name: '',
                        })]
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    removeDestination(threadId: string, destinationEmail: string) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        destination: thread.emails[0].destination.filter(destination => destination.emailAdress !== destinationEmail)
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    updateSubject(threadId: string, subject: string) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        subject
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    updateContent(threadId: string, content: string) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        content
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    addAttachments(threadId: string, attachments: Attachment[]) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                if(attachments.every(attachment => attachment.url)) {
                    const newThread = thread.copyWith({
                        emails: [thread.emails[0].copyWith({
                            attachments: [...thread.emails[0].attachments, ...attachments].filter(attachment => !!attachment.url)
                        })]
                    })
                    this.threadChanged$.next(newThread);
                    return newThread;
                }
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        attachments: uniqBy([...thread.emails[0].attachments, ...attachments], 'name')
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    removeAttachment(threadId: string, attachmentId: string) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        attachments: thread.emails[0].attachments.filter(attachment => attachment.id !== attachmentId)
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    addCc(threadId: string, ccEmail: string | EmailAuthor) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        cc: [...thread.emails[0].cc!, isEmailAuthor(ccEmail) ? ccEmail : new EmailAuthor({
                            emailAdress: ccEmail,
                            isVerified: false,
                            name: '',
                        })]
                    })]
                })
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }

    removeCc(threadId: string, ccId: string) {
        this.draftThreads.update(draftThreads => draftThreads.map(thread => {
            if (thread.id === threadId) {
                const newThread = thread.copyWith({
                    emails: [thread.emails[0].copyWith({
                        cc: thread.emails[0].cc?.filter(cc => cc.id !== ccId)
                    })]
                });
                this.threadChanged$.next(newThread);
                return newThread;
            }
            return thread;
        }))
    }
    
    openFullThread(thread: Thread) {
        this._dialogRef.open(NewEmailModalComponent, {
            width: '80%',
            data: {
                thread
            }
        });
    }
}

function isEmailAuthor(email: string | EmailAuthor): email is EmailAuthor {
    return (email as EmailAuthor).emailAdress !== undefined;
}