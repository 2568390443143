import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { HlmButtonDirective, HlmIconComponent, HlmInputModule, HlmSeparatorDirective, HlmToasterComponent, provideIcons } from '@recapp/ui';
import { BrnSeparatorComponent } from '@spartan-ng/ui-separator-brain';
import { toast } from 'ngx-sonner';
import { lucideLoader2 } from '@ng-icons/lucide';
import { SvgLogoPathResolverPipe } from '@web/app/shared/pipes/illustration-path-resolver.pipe';
import { LocalStorageKey } from '@web/app/shared/enums/local-storage-key';
import { Store } from '@ngrx/store';

import { User } from '@web/app/modules/users/models/user.model';
import { addUser, selectUser } from '@web/app/modules/users/store/user.actions';
@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        HlmInputModule,
        HlmButtonDirective,
        BrnSeparatorComponent,
        HlmSeparatorDirective,
        HlmToasterComponent,
        HlmIconComponent,
        SvgLogoPathResolverPipe,
        RouterModule
    ],
    providers: [provideIcons({ lucideLoader2 })],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    loadingRedirectUrlForGoogle = signal(false);

    private readonly _formBuilder: FormBuilder = inject(FormBuilder);
    private readonly _authService: AuthService = inject(AuthService);
    private readonly _router: Router = inject(Router);
    private readonly _store: Store = inject(Store);
    private readonly _activatedRoute = inject(ActivatedRoute);

    loginForm = this._formBuilder.group({
        email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
        password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    });

    hidePassword = true;

    isLoggingIn = signal(false);

    toggleHidePassword(): void {
        this.hidePassword = !this.hidePassword;
    }

    loginWithGoogle(): void {
        const canRegister = this._activatedRoute.snapshot.queryParams['registrationid'];
        if (!canRegister) {
            toast.info('The beta test is not open yet, request early access now', {
                duration: 5000,
                position: 'bottom-center',
                action: {
                    label: 'Get early access',
                    onClick: () => window.open('https://airtable.com/appl8CswP7FbLxOke/pagdy5Z7YSSh0qLgP/form', '_blank'),
                },
            });
            return;
        }
        this.loadingRedirectUrlForGoogle.set(true);
        this._authService.siginWithGoogle().subscribe({
            next: (res) => {
                // open small window with google sign in url
                window.open(res.data, '_blank');
                this.loadingRedirectUrlForGoogle.set(false);
            },
            error: (err) => {
                this.loadingRedirectUrlForGoogle.set(false);
                console.error(err);
            },
        });
    }

    login(): void {
        this.isLoggingIn.set(true);
        this._authService.login(this.loginForm.getRawValue()).subscribe({
            next: (res) => {
                localStorage.setItem(`${res.data.user.id}_${LocalStorageKey.JWT_TOKEN}`, res.data.token);
                const user = User.fromDto(res.data.user);
                this._store.dispatch(addUser({ user }));
                this._store.dispatch(selectUser({ id: user.id }));

                this.isLoggingIn.set(false);
                this._router.navigate(['/mail']);
            },
            error: (err) => {
                this.isLoggingIn.set(false);
                console.error(err);
                toast.error('Oops, une erreur est survenue', {
                    description: JSON.parse(err.message).message,
                });
            },
        });
    }

    navigateToForgotPassword(): void {
        this._router.navigate(['/auth', 'forgot-password']);
    }
}
