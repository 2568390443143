@if (section() && sidenavContext.opened()) {
    <span class="text-sm text-light fade-in-30">{{ section() }}</span>
} @else if (section() && !sidenavContext.opened()) {
    <div class="h-6"></div>
}
<div
    class="nav-item my-1.5 flex h-7 w-full cursor-pointer items-center justify-between rounded-sm px-3 relative"
    routerLinkActive="is-route-active"
    [ngClass]="{ 'w-max gap-x-2 !px-2': !sidenavContext.opened() }"
    [routerLink]="[routeName()]">
    <div class="flex items-center gap-x-2">
        <hlm-icon
            class="text-light"
            name="{{ icon() }}"
            size="sm"
            [style]="{
                color: iconColor() + ' !important'
            }"></hlm-icon>
        @if (sidenavContext.opened()) {
            <span class="appear truncate text-sm text-light">{{ text() }}</span>
        }
    </div>

    <ng-content></ng-content>
</div>
