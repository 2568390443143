<cmdk-command class="h-full bg-light-bg" [loop]="true" [loading]="isLoading()" [filter]="null">
    <hlm-input-form-field>
        <input
            class="w-full rounded-none border-l-0 border-r-0 border-t-0 !border-light-bg-darker bg-transparent px-5 py-8 text-light focus:!outline-none focus:ring-0"
            cmdkInput
            hlmInput
            placeholder="{{ 'common.search_placeholder' | translate }}"
            (input)="onSearchChange($event)" />
        @if (!isLoading()) {
            <hlm-icon class="mt-5 text-light" name="lucideSearch" hlmInputSuffix size="sm"></hlm-icon>
        } @else {
            <hlm-spinner class="mt-5 text-light" hlmInputSuffix size="xs"></hlm-spinner>
        }
    </hlm-input-form-field>

    <div *cmdkEmpty class="!m-4 text-sm text-light">No results found.</div>

    <cmdk-list>
        <button
            class="mb-2 flex w-full items-center justify-between rounded-md p-3 text-start text-sm text-light transition-all hover:bg-light-bg-darker"
            cmdkItem
            (selected)="close(); newEmailContext.addDraftEmail()">
            <div class="flex max-w-[80%] items-center gap-x-2">
                <hlm-icon name="lucidePencil" size="sm"></hlm-icon>
                <span class="flex-2 truncate font-medium">Nouveau message</span>
            </div>
        </button>
        @if (!isLoading() && filteredThreads().length) {
            @for (email of filteredThreads(); track $index) {
                @if (!(isPreviousEmailSameWeek | applyPure: $index)) {
                    <div class="mb-2 flex w-fit items-center gap-x-1 px-1 py-0.5 text-xs text-black">
                        <span class="capitalize">
                            {{ email.getFormattedDateRelativeToDay() ?? email.getFormattedDateRelativeToDay() }}
                        </span>
                    </div>
                }
                <button
                    class="mb-2 flex w-full items-center justify-between rounded-md p-3 text-start text-sm text-light transition-all hover:bg-light-bg-darker"
                    cmdkItem
                    (selected)="onSelected(email.threadId)">
                    <div class="flex max-w-[100%] items-center gap-x-2">
                        <span class="item-subject flex-2 truncate font-medium">{{ email.subject }}</span>
                        <span class="item-content flex-3 truncate">{{ email.summary ?? email.snippet ?? email.content }}</span>
                    </div>
                </button>
            }
        }

        @if (isLoading()) {
            @for (i of placeholderArray; track $index) {
                <hlm-skeleton class="mb-2 h-10 w-full" />
            }
        }
    </cmdk-list>
</cmdk-command>
