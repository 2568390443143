<div class="flex h-full w-full flex-col justify-between overflow-y-auto border-r border-r-light-bg-darker px-4 py-5">
    <div class="flex flex-col">
            <div class="mt-4 flex w-full flex-col gap-y-2">
                <div class="flex w-full items-center justify-between">
                    <div class="text-sm text-black">{{ 'common.todos' | translate }}</div>
                    <hlm-icon class="text-secondary-accent" name="lucideSparkles" size="sm" [matTooltip]="'Generated with AI'"></hlm-icon>
                </div>
                <div class="min-h-[70px] w-full justify-between rounded-[10px] border border-light-bg-darker bg-white p-3">
                    <app-text-editor
                        [checkboxOnly]="true"
                        class="w-full overflow-y-auto border-none text-xs outline-none"
                        [editorId]="'actionItemsEditor'"
                        (onChangeOutputData)="actionItemsChanged($event)"
                        [initialData]="actionItems()"></app-text-editor>
                </div>
            </div>
    </div>
    <!-- Comments section available soon -->
    <div class="mt-4 flex w-full flex-col gap-y-2">
        <div class="flex w-full items-center justify-between">
            <div class="text-sm text-black">{{ 'common.comments' | translate }}</div>
        </div>
        <div
            class="relative flex min-h-[200px] w-full flex-col justify-between rounded-[10px] border border-light-bg-darker bg-white p-3"
            [matTooltip]="'Comments section will be available soon'">
            <div class="flex h-full flex-col overflow-y-auto">
                <div class="flex gap-x-2">
                    <div class="flex w-full flex-col">
                        <div class="flex w-full items-center justify-between gap-x-2">
                            <div class="flex items-center gap-x-2">
                                <app-avatar size="sm" hlmInputPrefix [avatars]="[this.currentUser()!.getAvatar()]"></app-avatar>
                                <div class="text-sm font-medium text-black">
                                    {{ this.currentUser()?.getFullName() }}
                                </div>
                                <div class="text-xs text-light">{{ 'common.just_now' | translate }}</div>
                            </div>

                            <button hlmBtn [size]="'sm'" [variant]="'ghost'">
                                <hlm-icon class="text-light" name="lucideMoreHorizontal" size="sm"></hlm-icon>
                            </button>
                        </div>
                        <div class="flex items-center gap-x-2">
                            <app-avatar
                                class="opacity-0"
                                size="sm"
                                hlmInputPrefix
                                [avatars]="[this.currentUser()!.getAvatar()]"></app-avatar>
                            <div class="text-xs text-black">Lorem ipsum dolor sit amet</div>
                        </div>
                    </div>
                </div>
            </div>
            <hlm-input-form-field>
                <!--  -->
                <app-avatar size="sm" hlmInputPrefix [avatars]="[this.currentUser()!.getAvatar()]"></app-avatar>
                <input class="w-full !pl-12 text-xs" hlmInput formControlName="password" placeholder="Leave a comment here @.." />
            </hlm-input-form-field>

            <!-- AVAILABLE SOON MASk -->
            <div class="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-[10px] bg-white/70"></div>
        </div>
    </div>
</div>
