import { ChangeDetectionStrategy, computed, OnDestroy, signal } from '@angular/core';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    lucideArrowLeft,
    lucideChevronLeft,
    lucideDownload,
    lucideImage,
    lucideLink,
    lucideMusic,
    lucidePaperclip,
    lucidePrinter,
    lucideVideo,
} from '@ng-icons/lucide';
import { AttachmentType } from '@recapp/shared-types';
import { HlmButtonDirective, HlmIconComponent, HlmSpinnerComponent, provideIcons } from '@recapp/ui';
import { Attachment } from '@web/app/modules/inbox/models/attachment.model';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CustomDialogService } from '../../services/custom-dialog.service';

@Component({
    selector: 'app-attachment-preview-modal',
    templateUrl: './attachment-preview-modal.component.html',
    styleUrls: ['./attachment-preview-modal.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgxDocViewerModule, HlmSpinnerComponent, HlmIconComponent, HlmButtonDirective],
    providers: [
        provideIcons({
            lucideChevronLeft,
            lucidePaperclip,
            lucideImage,
            lucideVideo,
            lucideLink,
            lucideMusic,
            lucideArrowLeft,
            lucideDownload,
            lucidePrinter,
        }),
    ],
})
export class AttachmentPreviewModalComponent implements OnDestroy {
    readonly data = inject<{
        attachments: Attachment[];
        attachmentIndex: number;
    }>(MAT_DIALOG_DATA);

    private readonly _dialogRef = inject(CustomDialogService);

    readonly AttachmentType = AttachmentType;

    currentAttachmentIndex = signal(this.data.attachmentIndex);

    isDocumentViewerLoaded = signal(false);

    currentAttachment = computed(() => this.data.attachments[this.currentAttachmentIndex()]);

    hasPreviousAttachment = computed(() => this.currentAttachmentIndex() > 0);

    hasNextAttachment = computed(() => this.currentAttachmentIndex() < this.data.attachments.length - 1);

    shouldDisplayWithDocumentViewer = computed(() => {
        return [AttachmentType.PDF, AttachmentType.DOCUMENT].includes(this.currentAttachment().type);
    });

    ngOnDestroy(): void {
        document.removeEventListener('keydown', (event) => this.onKeyDown(event));
    }

    onViewerLoaded(): void {
        this.isDocumentViewerLoaded.set(true);
        document.addEventListener('keydown', (event) => this.onKeyDown(event));
    }

    onPreviousAttachment(): void {
        this.isDocumentViewerLoaded.set(false);
        this.currentAttachmentIndex.set(this.currentAttachmentIndex() - 1);
    }

    onNextAttachment(): void {
        this.isDocumentViewerLoaded.set(false);
        this.currentAttachmentIndex.set(this.currentAttachmentIndex() + 1);
    }

    downloadAttachment(): void {
        const link = document.createElement('a');
        link.href = this.currentAttachment().url;
        link.download = this.currentAttachment().name;
        link.click();
    }

    closeDialog(): void {
        this._dialogRef.closeAll();
    }

    private onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'ArrowLeft' && this.hasPreviousAttachment()) {
            this.onPreviousAttachment();
        } else if (event.key === 'ArrowRight' && this.hasNextAttachment()) {
            this.onNextAttachment();
        }
    }
}
