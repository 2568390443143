import { inject, Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { ApiResult, TApiResult } from '@recapp/shared-types';
import { UpdatePasswordDTO, UserDTO } from '@recapp/dto';
import { TrpcService } from '../../../core/services/trpc.service';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UsersService {
    private readonly _trpcService = inject(TrpcService);

    getUser(id: string): Observable<TApiResult<User>> {
        return from(this._trpcService.api.users.getUser(this._trpcService.connection, id)).pipe(
            map((res) => ({
                ...res,
                data: User.fromDto(res.data),
            }))
        );
    }

    updatePassword(data: UpdatePasswordDTO): Observable<ApiResult<UserDTO>> {
        return from(this._trpcService.api.users.update_password.updatePassword(this._trpcService.connection, data));
    }
}
