import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';
import { User } from '../models/user.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { State } from '@web/app/core/store/storage-meta';
import { LocalStorageKey } from '@web/app/shared/enums/local-storage-key';

export interface UserState extends EntityState<User> {
    // additional entity state properties
    selectedUserId: string | null;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId: (user: User) => user.id,
    sortComparer: false,
});

export const initialState: UserState = adapter.getInitialState({
    // additional entity state properties
    selectedUserId: null,
});

const _userReducer = createReducer(
    initialState,
    on(UserActions.addUser, (state, action) => adapter.addOne(action.user, state)),
    on(UserActions.upsertUser, (state, action) => adapter.upsertOne(action.user, state)),
    on(UserActions.addUsers, (state, action) => adapter.addMany(action.users, state)),
    on(UserActions.upsertUsers, (state, action) => adapter.upsertMany(action.users, state)),
    on(UserActions.updateUser, (state, action) => adapter.updateOne(action.user, state)),
    on(UserActions.updateUsers, (state, action) => adapter.updateMany(action.users, state)),
    on(UserActions.deleteUser, (state, action) => adapter.removeOne(action.id, state)),
    on(UserActions.deleteUsers, (state, action) => adapter.removeMany(action.ids, state)),
    on(UserActions.loadUsers, (state, action) => adapter.setAll(action.users, state)),
    on(UserActions.clearUsers, (state) => adapter.removeAll(state)),
    on(UserActions.selectUser, (state, action) => {
        localStorage.setItem(LocalStorageKey.USER_ID, action.id);
        return {
            ...state,
            selectedUserId: action.id,
        };
    }),
    on(UserActions.logoutUser, (state) => {
        const { [state.selectedUserId!]: _, ...entities } = state.entities;
        console.log('logoutUser', state.selectedUserId);
        localStorage.removeItem(`${state.selectedUserId}_${LocalStorageKey.JWT_TOKEN}`);
        localStorage.removeItem(LocalStorageKey.USER_ID);
        const newState = {
            ...state,
            entities,
            ids: state.ids.filter((id) => id !== state.selectedUserId!) as string[],
            selectedUserId: null,
        };
        return newState;
    }),
    on(UserActions.logoutAllUsers, (state) => {
        localStorage.removeItem(LocalStorageKey.USER_ID);
        localStorage.removeItem(LocalStorageKey.JWT_TOKEN);
        for (const userId of state.ids) {
            localStorage.removeItem(`${userId}_${LocalStorageKey.JWT_TOKEN}`);
        }
        return {
            ...state,
            selectedUserId: null,
            entities: {},
            ids: [],
        };
    })
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
    return _userReducer(state, action);
}

const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const selectUserIds = selectIds;
export const selectUserEntities = selectEntities;
export const selectAllUsers = (state: State) => {
    const users = selectAll(state.user);
    return users.map((user) => new User(user));
};
export const selectUserTotal = selectTotal;

export const selectSelectedUserId = (state: State) => {
    return state.user.selectedUserId;
};
export const selectUser = (state: State): User | null => {
    if (!state.user.selectedUserId) {
        return null;
    }
    return new User(state.user.entities[state.user.selectedUserId]!);
};

export const selectCurrentUser = (state: State): User | null => {
    const selectedUserId = selectSelectedUserId(state);
    if (!selectedUserId) {
        return null;
    }
    return selectUser(state);
};
