import { ID } from '@recapp/shared-types';
import { Types } from 'mongoose';

export const provideID = (): string => {
    return new Types.ObjectId().toString();
};

export const toID = (id: string): ID => {
    if (!id) {
        throw new Error('ID is required');
    }
    return new Types.ObjectId(id?.toString());
};

export const toPersistence = (obj: any): any => {
    if (isObjectId(obj)) {
        return toID(obj);
    }
    for (const key in obj) {
        if (key === 'id' && obj[key]) {
            obj['_id'] = toID(obj[key]?.toString());
            delete obj['id'];
        } else if (obj[key] && isArray(obj[key])) {
            obj[key] = obj[key].map((item: any) => toPersistence(item));
        } else if (obj[key] && typeof obj[key] === 'object' && obj[key]['id']) {
            obj[key] = toPersistence(obj[key]);
        } else if (obj[key] && isObjectId(obj[key]?.toString())) {
            if (key === 'id') {
                obj['_id'] = toID(obj[key]?.toString());
                delete obj['id'];
            } else {
                obj[key] = toID(obj[key]?.toString());
            }
        }
    }
    return obj;
};

export const toEntity = (obj: any): any => {
    if (!obj) return obj;
    if (obj?._id) {
        obj.id = obj._id.toString();
        delete obj._id;
    }
    if (isArray(obj) && isObjectId(obj[0].toString())) {
        return obj.map((item: any) => item.toString());
    }
    for (const key in obj) {
        if (
            obj[key] &&
            isObjectId(obj[key]?.toString()) &&
            !isArray(obj[key])
        ) {
            obj[key] = obj[key].toString();
        } else if (obj[key] && isArray(obj[key])) {
            obj[key] = obj[key].map((item: any) => {
                if (item && isObjectId(item?.toString())) {
                    return item.toString();
                }
                return toEntity(item);
            });
        } else if (
            obj[key] &&
            typeof obj[key] === 'object' &&
            obj[key]['_id']
        ) {
            obj[key] = toEntity(obj[key]);
        }
    }
    return obj;
};

export const isObjectId = (id: string): boolean => {
    try {
        return id?.toString()?.match(/^[0-9a-fA-F]{24}$/) != null;
    } catch (error) {
        return false;
    }
};

const isArray = (obj: any): boolean => {
    return Array.isArray(obj);
};
