import { inject, Injectable } from '@angular/core';
import { SystemLabel } from '@recapp/shared-types';
import { injectMutation, injectQueryClient } from '@tanstack/angular-query-experimental';
import { Email } from '../models/email.model';
import { Thread } from '../models/thread.model';
import { ThreadsService } from '@web/app/shared/components/thread-view-modal/services/threads.service';
import { selectSelectedUserId, UserState } from '../../users/store/user.reducer';
import { Store } from '@ngrx/store';
import { Label } from '../models/label.model';
import { TrashThreadsDTO } from '@recapp/dto';
import { toast } from 'ngx-sonner';
import { View } from '@web/app/modules/inbox/models/view.model';

@Injectable({
    providedIn: 'root',
})
export class InboxMutations {
    private readonly _threadsService = inject(ThreadsService);
    private readonly _store = inject(Store<UserState>);
    private readonly _currentUserId = this._store.selectSignal(selectSelectedUserId);
    private readonly queryClient = injectQueryClient();

    readonly updateThreadReadStateMutation = injectMutation(() => ({
        mutationFn: (params: any) => this._threadsService.updateThreadReadState(params),
        onMutate: (variables: {
            threadsIds: string[];
            isRead: boolean;
            currentSelectedLabels: string[];
            isUnreadTabSelected: boolean;
            userLabels: Label[];
            view?: View | null
        }) => {
            if(variables.view) {
                this.queryClient.setQueryData(
                    [
                        this._currentUserId(),
                        'view',
                        variables.view.id,
                        ...variables.currentSelectedLabels,
                        `?groupedBy=${variables.view.getGroupByKeys().join(',')}`,
                        `&${variables.view.getFilterByKeyValuesAsQueryParams()}`,
                    ],
                    (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.map((t: { [key: string]: any; threads: Thread[] }) => {
                                return {
                                    ...t,
                                    threads: t.threads.map((thread: Thread) => {
                                        if (variables.threadsIds.includes(thread.id)) {
                                            return new Thread({
                                                ...thread,
                                                labels: variables.isRead
                                                    ? thread.labels?.filter((l) => l.name !== SystemLabel.UNREAD)
                                                    : [
                                                          ...(thread.labels?.filter((l) => l.name !== SystemLabel.UNREAD) ?? []),
                                                          variables.userLabels.find((l) => l.name === SystemLabel.UNREAD)!,
                                                      ],
                                                emails: [
                                                    ...thread.emails.map((email) => {
                                                        const newLabels = email.labels.filter((l) => l.name !== SystemLabel.UNREAD);

                                                        if (!variables.isRead) {
                                                            newLabels.push(
                                                                variables.userLabels.find((l) => l.name === SystemLabel.UNREAD)!
                                                            );
                                                        }
                                                        return new Email({
                                                            ...email,
                                                            labels: newLabels,
                                                        });
                                                    }),
                                                ],
                                            });
                                        }
                                        return thread;
                                    })
                                };
                            }),
                        };
                    }),
                };
            }
                );
            }
            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                const newData = {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: variables.isUnreadTabSelected
                                ? page.data.filter((thread: Thread) => !variables.threadsIds.includes(thread.id))
                                : [
                                      ...page.data.map((thread: Thread) => {
                                          if (variables.threadsIds.includes(thread.id)) {
                                              return new Thread({
                                                  ...thread,
                                                  labels: variables.isRead
                                                      ? thread.labels?.filter((l) => l.name !== SystemLabel.UNREAD)
                                                      : [
                                                            ...(thread.labels?.filter((l) => l.name !== SystemLabel.UNREAD) ?? []),
                                                            variables.userLabels.find((l) => l.name === SystemLabel.UNREAD)!,
                                                        ],
                                                  emails: [
                                                      ...thread.emails.map((email) => {
                                                          const newLabels = email.labels.filter((l) => l.name !== SystemLabel.UNREAD);

                                                          if (!variables.isRead) {
                                                              newLabels.push(
                                                                  variables.userLabels.find((l) => l.name === SystemLabel.UNREAD)!
                                                              );
                                                          }
                                                          return new Email({
                                                              ...email,
                                                              labels: newLabels,
                                                          });
                                                      }),
                                                  ],
                                              });
                                          }
                                          return thread;
                                      }),
                                  ],
                        };
                    }),
                };
                return newData;
            });
            this._updateUnreadThreadsCount(variables.threadsIds, !variables.isRead);
        },

        onError: (error, variables, context) => {
            console.error(error);
            toast.error('Error updating read state');
        },
    }));

    readonly trashThreadsMutation = injectMutation(() => ({
        mutationFn: ({ threadsIds }: TrashThreadsDTO) => this._threadsService.trashThreads(threadsIds),
        onMutate: (variables: { threadsIds: string[]; currentSelectedLabels: string[], view?: View | null }) => {
            if(variables.view) {
                this.queryClient.setQueryData(
                    [
                        this._currentUserId(),
                        'view',
                        variables.view.id,
                        ...variables.currentSelectedLabels,
                        `?groupedBy=${variables.view.getGroupByKeys().join(',')}`,
                        `&${variables.view.getFilterByKeyValuesAsQueryParams()}`,
                    ],
                    (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.filter((thread: Thread) => !variables.threadsIds.includes(thread.id)),
                        };
                    }),
                };
            }
                );
            }
            
            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.filter((thread: Thread) => !variables.threadsIds.includes(thread.id)),
                        };
                    }),
                };
            });

            this._updateUnreadThreadsCount(variables.threadsIds, false);
        },
        onError: (error) => {
            console.error(error);
        },
    }));

    readonly deleteThreadsMutation = injectMutation(() => ({
        mutationFn: ({ threadsIds }: TrashThreadsDTO) => this._threadsService.deleteThreads(threadsIds),
        onMutate: (variables: { threadsIds: string[]; currentSelectedLabels: string[], view?: View | null }) => {
            if(variables.view) {
                this.queryClient.setQueryData(
                    [
                        this._currentUserId(),
                        'view',
                        variables.view.id,
                        ...variables.currentSelectedLabels,
                        `?groupedBy=${variables.view.getGroupByKeys().join(',')}`,
                        `&${variables.view.getFilterByKeyValuesAsQueryParams()}`,
                    ],
                    (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.filter((thread: Thread) => !variables.threadsIds.includes(thread.id)),
                        };
                    }),
                };
            }
                );
            }
            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.filter((thread: Thread) => !variables.threadsIds.includes(thread.id)),
                        };
                    }),
                };
            });
        },
        onError: (error) => {
            console.error(error);
            toast.error('Error deleting threads');
        },
    }));

    readonly updateThreadLabelsMutation = injectMutation(() => ({
        mutationFn: ({
            threadIds,
            labelsToAdd,
            labelsToRemove,
        }: {
            threadIds: string[];
            labelsToAdd: Label[];
            labelsToRemove: Label[];
            currentSelectedLabels: string[];
            view?: View | null;
        }) =>
            this._threadsService.updateThreadLabels({
                threadIds,
                labelsToAdd: labelsToAdd.map((l) => l.id),
                labelsToRemove: labelsToRemove.map((l) => l.id),
            }),
        onMutate: (variables) => {
            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.map((thread: Thread) => {
                                if (variables.threadIds.includes(thread.id)) {
                                    return new Thread({
                                        ...thread,
                                        labels: [
                                            ...(thread.labels?.filter(
                                                (l) => !variables.labelsToRemove.find((label) => label.id === l.id)
                                            ) ?? []),
                                            ...variables.labelsToAdd,
                                        ],
                                        emails: thread.emails.map((email) => {
                                            const newLabels = email.labels.filter(
                                                (l) => !variables.labelsToRemove.find((label) => label.id === l.id)
                                            );
                                            newLabels.push(...variables.labelsToAdd);
                                            return new Email({
                                                ...email,
                                                labels: newLabels,
                                            });
                                        }),
                                    });
                                }
                                return thread;
                            }),
                        };
                    }),
                };
            });
            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.map((thread: Thread) => {
                                if (variables.threadIds.includes(thread.id)) {
                                    return new Thread({
                                        ...thread,
                                        labels: [
                                            ...(thread.labels?.filter(
                                                (l) => !variables.labelsToRemove.find((label) => label.id === l.id)
                                            ) ?? []),
                                            ...variables.labelsToAdd,
                                        ],
                                        emails: thread.emails.map((email) => {
                                            const newLabels = email.labels.filter(
                                                (l) => !variables.labelsToRemove.find((label) => label.id === l.id)
                                            );
                                            newLabels.push(...variables.labelsToAdd);
                                            return new Email({
                                                ...email,
                                                labels: newLabels,
                                            });
                                        }),
                                    });
                                }
                                return thread;
                            }),
                        };
                    }),
                };
            });
            this._updateUnreadThreadsCount(variables.threadIds, !variables.labelsToAdd.some((l) => l.name === SystemLabel.ARCHIVED));
        },

        onSuccess: (data, { currentSelectedLabels, threadIds, labelsToAdd }) => {
            this.queryClient.invalidateQueries({
                queryKey: [this._currentUserId(), ...currentSelectedLabels],
            });
        },

        onError: (error) => {
            console.error(error);
            toast.error('Error updating labels');
        },
    }));

    private _updateUnreadThreadsCount(threadIds: string[], shouldIncrement: boolean) {
        this.queryClient.setQueryData([this._currentUserId(), 'unread-threads-count'], (oldData: any) => {
            if (!oldData) {
                return oldData;
            }
            return {
                ...oldData,
                data: shouldIncrement
                    ? [...new Set([...oldData.data, ...threadIds])]
                    : (oldData.data as string[]).filter((id) => !threadIds.includes(id)),
            };
        });
    }
    readonly updateThreadCompletedStateMutation = injectMutation(() => ({
        mutationFn: (params: any) => this._threadsService.updateThreadCompleteState(params),
        onMutate: (variables: { threadsIds: string[]; isCompleted: boolean; currentSelectedLabels: string[] }) => {
            console.log(variables);

            this.queryClient.setQueryData([this._currentUserId(), ...variables.currentSelectedLabels], (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.map((thread: Thread) => {
                                if (variables.threadsIds.includes(thread.id)) {
                                    return new Thread({
                                        ...thread,
                                        actionItems: thread.actionItems?.map((item) => {
                                            if (item.completed === variables.isCompleted) {
                                                return {
                                                    ...item,
                                                    completed: !variables.isCompleted,
                                                };
                                            }
                                            return item;
                                        }),
                                    });
                                }
                                return thread;
                            }),
                        };
                    }),
                };
            });
        },
        onSuccess: (data, { currentSelectedLabels }) => {
            this.queryClient.invalidateQueries({
                queryKey: [this._currentUserId(), ...currentSelectedLabels],
            });

            toast.success('Task updated !');
        },
        onError: (error) => {
            console.error(error);
            toast.error('Error updating thread complete state');
        },
    }));
}
