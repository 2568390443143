export const uniqBy = <T>(array: T[], key: keyof T): T[] => {
    return array.filter(
        (item, index, self) =>
            self.findIndex((t) => t[key] === item[key]) === index,
    );
};

export function formatArrayKeysForQueryParams(
    obj: Record<string, any>,
): Record<string, any> {
    const newObj: any = {};

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            newObj[`${key}[]`] = obj[key];
        } else {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

export const groupBy = <T>(
    array: T[],
    iterator: (item: T) => string,
): Record<string, T[]> => {
    return array.reduce(
        (acc, item) => {
            const key = iterator(item);
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        },
        {} as Record<string, T[]>,
    );
};

export const isArray = (value: any): value is any[] => {
    return Array.isArray(value);
}
