import { z } from 'zod';
import { createPlatformSchema } from '../platforms';

// Define the Zod schema
export const createUserSchema = z.object({
    email: z.string().email(),
    password: z.string().min(8).optional(),
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    platforms: z.array(createPlatformSchema),
});

// Define the type for CreateUserDTO
export interface CreateUserDTO extends z.infer<typeof createUserSchema> {}
