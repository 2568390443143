export default class HtmlParser {
    parseBlock(block: any) {
        switch (block.type) {
            case 'header':
                return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;

            case 'paragraph':
            case 'aiText':
                return `<p>${block.data.text}</p>`;

            case 'list':
                const listTag = block.data.style === 'ordered' ? 'ol' : 'ul';
                const items = block.data.items.map((item: any) => `<li>${item}</li>`).join('');
                return `<${listTag}>${items}</${listTag}>`;

            case 'checklist':
                const checklistItems = block.data.items
                    .map((item: any) => `<li><input type="checkbox" ${item.checked ? 'checked' : ''}> ${item.text}</li>`)
                    .join('');
                return `<ul>${checklistItems}</ul>`;

            case 'marker':
                return `<mark>${block.data.text}</mark>`;

            case 'inlineCode':
                return `<code>${block.data.text}</code>`;

            case 'underline':
                return `<u>${block.data.text}</u>`;

            case 'image':
                return `<img src="${block.data.file.url}" alt="${block.data.caption}" />`;

            default:
                return '';
        }
    }
}
