import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { lucideMoreHorizontal, lucidePlusCircle } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmIconComponent, HlmMenuModule } from '@recapp/ui';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { NavItemComponent } from '@web/app/core/layout/sidenav/components/nav-item/nav-item.component';
import { CreateViewDialogComponent } from '@web/app/core/layout/sidenav/components/sidenav-views/components/create-view-dialog/create-view-dialog';
import { SidenavContext } from '@web/app/core/layout/sidenav/context/sidenav.context';
import { ViewMutations } from '@web/app/modules/inbox/contexts/view.mutations';
import { ViewsContext } from '@web/app/modules/inbox/contexts/views.context';
import { View } from '@web/app/modules/inbox/models/view.model';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';

@Component({
    selector: 'app-sidenav-views',
    templateUrl: './sidenav-views.component.html',
    styleUrls: ['./sidenav-views.component.scss'],
    imports: [NavItemComponent, TranslateModule, HlmIconComponent, HlmMenuModule, BrnMenuTriggerDirective],
    providers: [
        provideIcons({
            lucidePlusCircle,
            lucideMoreHorizontal,
        }),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SidenavViewsComponent implements OnInit {
    readonly viewsContext = inject(ViewsContext);
    readonly viewMutations = inject(ViewMutations);
    readonly sidenavContext = inject(SidenavContext);
    readonly _dialogRef = inject(CustomDialogService);
    readonly router = inject(Router);

    ngOnInit(): void {
        this.viewsContext.init();
    }

    openCreateViewDialog(): void {
        this._dialogRef.open(CreateViewDialogComponent, {
            panelClass: ['rounded-xl', '!bg-light-bg', 'global-search'],
            width: '30vw',
            height: '25vh'
        });
    }

    deleteView(id: string): void {
        const isCurrentView = this.viewsContext.currentViewId() === id;
        if(isCurrentView) {
            this.router.navigate(['/mail/inbox']);
        }
        this.viewMutations.deleteViewMutation.mutate(id);
    }

    updateView(view: View): void {
        this._dialogRef.open(CreateViewDialogComponent, {
            data: { view },
            panelClass: ['rounded-xl', '!bg-light-bg', 'global-search'],
            width: '30vw',
            height: '25vh'
        })
    }
}
